import * as React from "react";
import styled from "styled-components";
import CircleSpinner from "./CircleSpinner";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div<{ textColor?: string }>`
  margin-right: 10px;
  color: ${(props: { textColor?: string }) =>
    props.textColor || "rgb(95, 95, 95);"};
`;

interface Props {
  label?: React.ReactNode;
  primaryColor?: string;
  secondaryColor?: string;
  textColor?: string;
}

const LoadingIndicator: React.FC<Props> = (props) => (
  <Wrapper>
    {props.label && <Label textColor={props.textColor}>{props.label}</Label>}
    <CircleSpinner />
  </Wrapper>
);
export default LoadingIndicator;
