import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormLabel,
  Heading,
  Image,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import CustomAlert from "components/CustomAlert";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import { observer } from "mobx-react";
import BottleLabel, { Customization } from "models/bottle-label";
import Cask from "models/cask";
import { FC } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { FormattedMessage } from "react-intl";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import * as Yup from "yup";
import { BottlingFormValues } from "./BottlingForm";
import { intersectingSectionAtom } from "./BottlingStepper";
import LabelPreview from "./label-editor/LabelPreview";

const ginLabel: string = require("assets/images/ginfront.jpg");

export interface BottlingLabelsPartValues {
  labels: BottleLabel[];
}

export const bottlingLabelsSchema = (format: (id: string) => any) =>
  Yup.object().shape({
    labels: Yup.array().of(
      Yup.object().shape({
        optOut: Yup.boolean(),
        touched: Yup.boolean().when("optOut", {
          is: false, // If optOut is false
          then: Yup.boolean().oneOf([true], format("common.required")), // touched must be true
        }),
      })
    ),
    percentOfLabels: Yup.object().when(["labels"], {
      is: (labels: BottleLabel[]) =>
        labels.length > 1 &&
        labels.reduce((p, c) => p + (c.percentOfLabels || 0), 0) !== 100,
      then: Yup.object().required(format("common.required")),
    }),
  });

const isOldBOXLabelAvailable = (cask?: Cask) => {
  if (!cask) return false;
  if (cask.filled === undefined) return false;

  let filled = new Date(cask.filled).getTime();
  let boxLabelLimit = new Date("2018-06-30").getTime();
  let diff = filled - boxLabelLimit;
  return diff < 0;
};

const LabelItem: FC<{
  labels: BottleLabel[];
  label: BottleLabel;
  index: number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}> = ({ labels, label, index, setFieldValue }) => {
  const { cask } = useCurrentCask();
  const boxLabelAvailable = isOldBOXLabelAvailable(cask);
  const percentageError =
    labels.reduce((p, c) => p + (c.percentOfLabels || 0), 0) !== 100;

  return (
    <Box mt={4}>
      <Box display="flex">
        <FormLabel
          fontSize="lg"
          fontWeight={300}
          htmlFor="bottlingInfo.fullBottling"
        >
          <FormattedMessage
            id="cask.orders.bottling.labels.titles"
            values={{ index: index + 1 }}
          />
        </FormLabel>
        {labels.length > 1 && (
          <Button
            marginBottom={2}
            color="red.500"
            variant="link"
            rightIcon={<BsFillTrashFill />}
            onClick={() =>
              setFieldValue("labels.labels", [
                ...labels.filter((l, i) => i !== index),
              ])
            }
          >
            <FormattedMessage id="common.remove" />{" "}
          </Button>
        )}
      </Box>
      {boxLabelAvailable && (
        <Box mt={4}>
          <Checkbox
            name={`labels.labels[${index}].oldBOXLabel`}
            onChange={(v) =>
              setFieldValue(
                `labels.labels[${index}].oldBOXLabel`,
                v.target.checked
              )
            }
          >
            <FormattedMessage id="cask.orders.bottling.labels.box" />
          </Checkbox>
        </Box>
      )}
      {label.customization !== undefined && (
        <Box my={4}>
          <LabelPreview label={label} index={index} />
          <Box mt={4}>
            <Checkbox
              name={`labels.labels[${index}].optOut`}
              onChange={(v) => {
                if (v.target.checked) {
                  setFieldValue(
                    `labels.labels[${index}].optOut`,
                    v.target.checked
                  );
                  setFieldValue(
                    `labels.labels[${index}].customization`,
                    Customization.None
                  );
                } else {
                  setFieldValue(
                    `labels.labels[${index}].optOut`,
                    v.target.checked
                  );
                  setFieldValue(
                    `labels.labels[${index}].customization`,
                    Customization.File
                  );
                }
              }}
            >
              <FormattedMessage id="cask.orders.bottling.labels.opt-out" />
            </Checkbox>
          </Box>
        </Box>
      )}
      {labels.length > 1 && (
        <Box my={4}>
          <Box mt={2} mb={4}>
            <Text fontWeight={300}>
              <FormattedMessage id="cask.orders.bottling.labels.percent" />
            </Text>
          </Box>
          <Box
            mt={4}
            pb={4}
            px={4}
            border="1px"
            borderColor={percentageError ? "red.500" : "gray.300"}
            pt={10}
          >
            <Slider
              aria-label="slider"
              onChange={(v) =>
                setFieldValue(`labels.labels[${index}].percentOfLabels`, v)
              }
            >
              <SliderMark
                value={label.percentOfLabels || 50}
                textAlign="center"
                bg="#3992AD"
                color="white"
                mt="-10"
                ml="-5"
                w="12"
                fontSize="lg"
              >
                {label.percentOfLabels}%
              </SliderMark>
              <SliderTrack mt={2} h={2} bg="#BDBDBD">
                <SliderFilledTrack bg="#3992AD" />
              </SliderTrack>
              <SliderThumb mt={2} borderColor="#BDBDBD" h={6} w={6} />
            </Slider>
          </Box>
          <Collapse in={percentageError} animateOpacity>
            <Text fontSize="sm" color="red.500" mt={4}>
              <FormattedMessage id="cask.orders.bottling.labels.sum-error" />
            </Text>
          </Collapse>
        </Box>
      )}

      <Divider my={6} />
    </Box>
  );
};

const LabelsFormPart = () => {
  const [, setCurrentSection] = useAtom(intersectingSectionAtom);
  const {
    values: {
      labels: { labels },
    },
    setFieldValue,
  } = useFormikContext<BottlingFormValues>();
  const { cask } = useCurrentCask();
  const isGin = cask?.recipe?.id === 3;

  return (
    <fieldset id="bottling-labels" onClick={() => setCurrentSection("labels")}>
      <Box pb={8}>
        <Box mt={6}>
          <Heading size="lg" fontWeight={500} mb={4}>
            <FormattedMessage id="cask.orders.bottling.labels.title" />
          </Heading>
          <Divider mt={2} mb={6} />
          <CustomAlert
            labelKey="orders.bottlingOrder.label.managerDescription"
            status="info"
            values={{ amount: "250" }}
          />
          <Box mt={4}>
            <CustomAlert
              labelKey="cask.orders.labels.content-warning"
              status="warning"
            />
          </Box>
          {isGin && (
            <Box my={4}>
              <CustomAlert
                labelKey="cask.orders.bottling.labels.gin.info"
                status="info"
              />
              <Image src={ginLabel.toString()} />
            </Box>
          )}
          {labels.map((l, i) => (
            <LabelItem
              labels={labels}
              key={`label-${i}`}
              index={i}
              label={l}
              setFieldValue={setFieldValue}
            />
          ))}
        </Box>
        <Button
          mt={2}
          w="full"
          borderColor="#807F7F"
          color="gray.900"
          size="lg"
          variant="outline"
          rightIcon={<GoPlus size="1.25rem" />}
          onClick={() => {
            const newLabels = [
              ...labels,
              { customization: Customization.None, percentOfLabels: 50 },
            ];
            setFieldValue(
              "labels.labels",
              newLabels.length === 2
                ? newLabels.map((l) => ({
                    ...l,
                    percentOfLabels: 50,
                  }))
                : newLabels
            );
          }}
        >
          <FormattedMessage id="cask.orders.bottling.labels.add" />
        </Button>
        {labels.length > 1 &&
          labels.every((l) => l.customization !== undefined) && (
            <Box mt={8}>
              <CustomAlert
                status="info"
                labelKey="cask.orders.bottling.labels.cost"
                values={{ cost: (labels.length - 1) * 250 }}
              />
            </Box>
          )}
      </Box>
    </fieldset>
  );
};

export default observer(LabelsFormPart);
