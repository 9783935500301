import * as fabric from "fabric"; // v6
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

export interface LabelState {
  selectedObject: fabric.Object | null;
  movingOrScaling: boolean;
  resizeImage: HTMLImageElement | null;
  sizeError: boolean | null;
}

const LabelEditorContext = createContext<{
  state: LabelState;
  setState: React.Dispatch<React.SetStateAction<LabelState>>;
} | null>(null);

export const LabelEditorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<LabelState>({
    selectedObject: null,
    movingOrScaling: false,
    resizeImage: null,
    sizeError: null,
  });

  return (
    <LabelEditorContext.Provider value={{ state, setState }}>
      {children}
    </LabelEditorContext.Provider>
  );
};

export const useLabelEditor = () => {
  const context = useContext(LabelEditorContext);
  if (!context) {
    throw new Error("useLabelEditor must be used within a LabelEditorProvider");
  }
  return context;
};
