import { action, computed, makeObservable, observable } from "mobx";
import Cask from "models/cask";
import CasksService from "services/casks";
import { sessionStore } from "stores/domain/session";

class CasksStore {
  @observable isLoading!: boolean;
  @observable private allCasks: Cask[] = [];

  constructor() {
    makeObservable(this);
  }

  /** The casks for the currently selected visma user */
  @computed get casks() {
    return this.allCasks
      .filter(
        (c) =>
          /* c.caskUsers.find(
          (cu) =>
            cu.vismaUser &&
            cu.vismaUser.vismaUserId === sessionStore.vismaUser.vismaUserId
        ) */
          true
      )
      .sort((a, b) =>
        (b.vismaCaskNumber || "").localeCompare(a.vismaCaskNumber || "")
      );
  }

  @computed
  get totalCasksCount() {
    return this.casks.length;
  }

  /** All the casks that the user owns or co-owns that doesn't have a visma user */
  @computed get casksNotAccepted() {
    return this.allCasks.filter((c) =>
      c.caskUsers.find(
        (cu) => !cu.vismaUser && cu.user.id === sessionStore.user.id
      )
    );
  }

  @action
  addCask(cask: Cask) {
    this.allCasks.push(cask);
  }

  @action
  updateCask(cask: Cask) {
    let index = this.allCasks.findIndex((c) => c.id === cask.id);
    if (index !== -1) {
      this.allCasks[index] = cask;
    }
  }

  @action
  async fetchCasks(resetLoadingState: boolean = true) {
    if (resetLoadingState) this.isLoading = true;
    let response = await CasksService.fetchCasks();

    if (!response.succeeded) {
      throw new Error(`Couldn't fetch user casks`);
    }

    this.allCasks = response.data;
    if (resetLoadingState) this.isLoading = false;
  }

  find(id: number): Cask {
    let cask = this.allCasks.find((c) => c.id === id);
    if (!cask) {
      throw new Error(`Cask with id ${id} couldn't be found`);
    }

    return cask;
  }
}

export default CasksStore;
export const casksStore = new CasksStore();
