import { action, computed, makeObservable, observable } from "mobx";
import { UserType } from "models/cask-user";
import User, { AccountType, LocalVismaUser } from "models/user";
import SessionService from "services/session";
import { casksStore } from "stores/domain/casks";
import { shoppingCartStore } from "stores/domain/shopping-cart";
import { shoppingCartUiStore } from "stores/ui/cask-shop/shopping-cart";

export const LS_ACCESS_TOKEN = "box_access_token";
export const LS_REFRESH_TOKEN = "box_refresh_token";
export const LS_EXPIRATION = "box_expiration";

class SessionStore {
  @observable user!: User;
  @observable isAuthenticated: boolean = false;
  @observable isFetchingUserData: boolean = false;

  constructor() {
    makeObservable(this);
  }

  private _accessToken: string | undefined;
  private _refreshToken: string | undefined;
  private _expirationDate: Date | undefined;

  @computed
  get vismaUser(): LocalVismaUser {
    return this.user?.vismaUser;
  }

  @action
  async fetchUser() {
    this.isFetchingUserData = true;
    let { succeeded, data } = await SessionService.getCurrentUser();
    if (!succeeded) {
      this.isFetchingUserData = false;
      this.logOut();
      return;
    }

    this.user = data;
    if (!this.user.vismaUser && this.user.vismaUsers) {
      this.user.vismaUser = this.user.vismaUsers[0];
    } else if (!!this.user.vismaUser && !this.user.vismaUsers) {
      // TODO Show the user a notice about their account not having any visma users and to contact support.
      // Maybe do this checking in the backend.
      this.isFetchingUserData = false;
      this.logOut();
      return;
    }
    this.isFetchingUserData = false;
    this.isAuthenticated = true;
  }

  isCaskOwner(caskId: number): boolean {
    let cask = casksStore.find(caskId);
    if (cask === undefined) {
      return false;
    }
    return (
      cask.caskUsers.find(
        (u) =>
          u.vismaUser &&
          this.user.vismaUsers.find(
            (vu) => vu.vismaUserId === u.vismaUser.vismaUserId
          ) &&
          u.userType === UserType.Owner
      ) !== undefined
    );
  }

  @computed
  get isPersonalAccount(): boolean {
    if (!this.user) {
      return true;
    }

    return this.user.vismaUser.accountType === AccountType.Person;
  }

  @action
  async setVismaUser(vismaUser: LocalVismaUser) {
    SessionService.setCurrentVismaUser(vismaUser.id);
    this.user.vismaUser = vismaUser;
    await this.loadUserData();
  }

  @computed
  get name() {
    if (this.user && this.vismaUser) {
      return this.isPersonalAccount
        ? `${this.vismaUser.firstName} ${this.vismaUser.lastName}`
        : this.user.vismaUser.orgName;
    }
    return "unkown";
  }

  @computed
  get nameInitials() {
    if (
      this.user &&
      this.vismaUser &&
      this.vismaUser.firstName &&
      this.vismaUser.lastName
    ) {
      return `${this.vismaUser.firstName[0]}${this.vismaUser.lastName[0]}`;
    }
    return "un";
  }

  @computed
  get nameWithId() {
    return this.user && this.vismaUser
      ? [this.name, this.vismaUser.vismaUserId]
      : [];
  }

  get accessToken(): string | undefined {
    if (this._accessToken) {
      return this._accessToken;
    }

    let lsToken = localStorage.getItem(LS_ACCESS_TOKEN);
    let token = lsToken !== null ? lsToken : undefined;
    return token;
  }

  set accessToken(token: string | undefined) {
    this._accessToken = token;

    if (token) {
      localStorage.setItem(LS_ACCESS_TOKEN, token);
    } else {
      localStorage.removeItem(LS_ACCESS_TOKEN);
    }
  }

  get refreshToken(): string | undefined {
    if (this._refreshToken) {
      return this._refreshToken;
    }

    let lsToken = localStorage.getItem(LS_REFRESH_TOKEN);
    let token = lsToken !== null ? lsToken : undefined;
    return token;
  }

  set refreshToken(token: string | undefined) {
    this._refreshToken = token;

    if (token) {
      localStorage.setItem(LS_REFRESH_TOKEN, token);
    } else {
      localStorage.removeItem(LS_REFRESH_TOKEN);
    }
  }

  get expirationDate(): Date | undefined {
    if (this._expirationDate) {
      return this._expirationDate;
    }

    let value = localStorage.getItem(LS_EXPIRATION);
    if (!value) {
      return;
    }

    let expirationDate = new Date(parseInt(value, 10));
    this._expirationDate = expirationDate;
    return expirationDate;
  }

  set expirationDate(expirationDate: Date | undefined) {
    this._expirationDate = expirationDate;

    if (expirationDate instanceof Date) {
      localStorage.setItem(LS_EXPIRATION, expirationDate.getTime().toString());
    } else {
      localStorage.removeItem(LS_EXPIRATION);
    }
  }

  @action
  async logIn(username: string, password: string) {
    let response = await SessionService.login(username, password);

    if (response.succeeded) {
      let { access_token, expires_in, refresh_token } = response.data;

      this.persistTokens(access_token, refresh_token, expires_in);
      this.logInCurrentUser();
    }

    return response;
  }

  @action
  async logInCurrentUser() {
    // if user has never logged in aka no tokens in ls
    if (
      this.accessToken === undefined ||
      this.refreshToken === undefined ||
      this.expirationDate === undefined
    ) {
      this.logOut();
      return;
    }

    // if user has an access token that is expired or about to, renew it
    if (new Date().getTime() > this.refreshDate.getTime()) {
      let succeeded = await this.refreshTokens();
      if (!succeeded) {
        this.logOut();
        return;
      }
    }

    await this.fetchUser();
    this.resetStores();
    await this.loadUserData();

    this.startRefreshTimer();

    // wait for the back end to sync the user (leave this commented for now)
    // setTimeout(
    //     async () => {
    //         await this.fetchUser();
    //         await this.loadUserData();
    //     },
    //     30 * 1000);
  }

  get refreshInMs() {
    return this.refreshDate.getTime() - new Date().getTime();
  }

  get refreshDate() {
    let refreshDate = new Date(this.expirationDate!.getTime());
    refreshDate.setMinutes(refreshDate.getMinutes() - 4);
    return refreshDate;
  }

  @action
  async startRefreshTimer() {
    if (this.isAuthenticated) {
      setTimeout(async () => {
        await this.refreshTokens();
        this.startRefreshTimer();
      }, this.refreshInMs);
    }
  }

  @action
  async refreshTokens(): Promise<boolean> {
    if (this.refreshToken !== undefined) {
      let response = await SessionService.refreshTokens();
      if (!response.succeeded) {
        this.logOut();
        return false;
      }

      let { access_token, expires_in, refresh_token } = response.data;
      this.persistTokens(access_token, refresh_token, expires_in);
    }

    return true;
  }

  resetStores() {
    if (this.isAuthenticated) {
      shoppingCartUiStore.initUserData();
      shoppingCartStore.setPaymentStep(false);
    }
  }

  @action
  loadUserData() {
    if (this.isAuthenticated) {
      casksStore.fetchCasks();
    }
  }

  @action
  logOut() {
    this.isAuthenticated = false;
    this.isFetchingUserData = false;
    this.removeTokens();
  }

  /**
   * Saves tokens in local storage
   *
   * @param accessToken
   * @param refreshToken
   * @param expiresIn
   */
  private persistTokens(
    accessToken: string,
    refreshToken: string,
    expiresIn: number
  ): void {
    let expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + expiresIn);

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.expirationDate = expirationDate;
  }

  private removeTokens() {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.expirationDate = undefined;
  }
}

export default SessionStore;
export const sessionStore = new SessionStore();
