import { Box } from "@chakra-ui/react";
import CustomAlert from "components/CustomAlert";
import { useFormikContext } from "formik";
import BottleLabel from "models/bottle-label";
import { FC } from "react";
import { useMeasure } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import styled, { css } from "styled-components";
import { BottlingFormValues } from "../BottlingForm";
import LabelEditor from "./LabelEditor";
import { LabelEditorProvider } from "./LabelEditorContext";

const blankLabel: string = require("assets/images/bottle-label-blank_HC.png");
const oldBoxLabel: string = require("assets/images/bottle-label-blank_BOX.jpg");
const ginLabel: string = require("assets/images/ginetikett.png");

export const LabelPreviewContainer = styled(Box)`
  cursor: default;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: calc(76vh - 100px);
`;

export const LabelPreviewInner = styled.img`
  max-height: calc(100vh - 100px);
  width: fit-content;
  border: 1px solid #bdbdbd;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const ImageFilePreview = styled.img<{ isGin: boolean }>`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  bottom: 22%;
  position: absolute;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  ${({ isGin }) =>
    isGin &&
    css`
      bottom: unset;
      top: 0;
      scale: 0.8;
    `}
`;

export type LabelSetting = {
  size: { height: number; width: number };
  labelOffset: number;
  scaleModifier?: number;
  outerTransfromOrigin?: string;
  controlsBottomOffset?: number;
};

const getLabelSettings: (args: {
  isGin: boolean;
  isBox: boolean;
}) => LabelSetting = ({ isBox, isGin }) => {
  if (isGin) {
    return {
      size: {
        width: 1500,
        height: 690,
      },
      labelOffset: 3950,
      scaleModifier: 0.85,
      outerTransfromOrigin: "center 30%",
      controlsBottomOffset: 1.06,
    };
  }
  if (isBox) {
    return {
      size: {
        width: 1800,
        height: 900,
      },
      scaleModifier: 0.85,
      outerTransfromOrigin: "center 30%",
      labelOffset: 700,
      controlsBottomOffset: 1.14,
    };
  }

  return {
    size: {
      width: 1800,
      height: 1050,
    },
    labelOffset: -600,
    scaleModifier: 0.85,
    outerTransfromOrigin: "center 30%",
    controlsBottomOffset: 1.24,
  };
};

const LabelPreview: FC<{
  label: BottleLabel;
  summary?: boolean;
  index: number;
}> = ({ label, summary, index }) => {
  const { cask } = useCurrentCask();
  const isGin = cask?.recipe?.id === 3;
  const [ref, { width }] = useMeasure<HTMLImageElement>();
  const {
    values: {
      labels: { labels },
    },
  } = useFormikContext<BottlingFormValues>();
  const optOut = labels[index].optOut;
  const { size, ...otherSettings } = getLabelSettings({
    isGin,
    isBox: !!label.oldBOXLabel,
  });

  return (
    <>
      {isGin && !summary && (
        <Box pb={6}>
          <CustomAlert
            labelKey="cask.orders.bottling.labels.gin.file-notice"
            status="info"
          />
        </Box>
      )}
      <LabelPreviewContainer
        paddingTop={summary || optOut ? undefined : "120px"}
      >
        <span style={{ display: optOut ? "none" : "block" }}>
          <LabelEditorProvider>
            {summary ? (
              <ImageFilePreview
                isGin={isGin}
                src={label.imageFile?.imagePreview}
              />
            ) : (
              <LabelEditor
                index={index}
                containerWidth={width}
                labelSize={size}
                {...otherSettings}
              />
            )}
          </LabelEditorProvider>
        </span>
        <LabelPreviewInner
          ref={ref}
          src={
            label.oldBOXLabel
              ? oldBoxLabel.toString()
              : isGin
              ? ginLabel.toString()
              : blankLabel.toString()
          }
        />
      </LabelPreviewContainer>
    </>
  );
};

export default LabelPreview;
