import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import CustomAlert from "components/CustomAlert";
import * as fabric from "fabric"; // v6
import { FC, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import "react-fontpicker-ts/dist/index.css";
import { BsTrash3 } from "react-icons/bs";
import {
  GrTextAlignCenter,
  GrTextAlignLeft,
  GrTextAlignRight,
} from "react-icons/gr";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { formatToString } from "utils/format";
import { useLabelEditor } from "./LabelEditorContext";
import LabelFontPicker, { FontFamily } from "./LabelFontPicker";
import { LabelImagePicker } from "./LabelImagePicker";
import { LabelTextPicker } from "./LabelTextPicker";

const ellipsisStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100px",
  maxWidth: "100px",
  display: "inline-block",
};

const TextPopover: FC<{ canvas: fabric.Canvas | null }> = ({ canvas }) => {
  const {
    state: { selectedObject },
  } = useLabelEditor();
  const [text, setText] = useState<string | undefined>(
    (selectedObject as fabric.Text)?.text
  );
  const setTextAlignment = (alignment: "left" | "center" | "right") => {
    if (!selectedObject) return;

    selectedObject.set({
      textAlign: alignment,
    });

    canvas?.renderAll();
  };

  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button
          mr={4}
          w="100%"
          id="text-edit-button"
          bgColor="white"
          cursor="text"
          style={ellipsisStyle}
        >
          {text || formatToString("cask.label.editor.text.placeholder")}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="auto">
        <Box p={4}>
          <Textarea
            id="text-input"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              (selectedObject as fabric.Text).set({
                text: e.target.value,
              });
              canvas?.renderAll();
            }}
          />
        </Box>
      </PopoverContent>
      <ButtonGroup isAttached size="sm">
        <IconButton
          icon={<GrTextAlignCenter />}
          aria-label="center"
          colorScheme={"brand"}
          id="text-adjust-center"
          onClick={() => setTextAlignment("center")}
        />
        <IconButton
          icon={<GrTextAlignLeft />}
          aria-label="left"
          colorScheme={"brand"}
          id="text-adjust-left"
          onClick={() => setTextAlignment("left")}
        />
        <IconButton
          icon={<GrTextAlignRight />}
          aria-label="right"
          colorScheme={"brand"}
          id="text-adjust-right"
          onClick={() => setTextAlignment("right")}
        />
      </ButtonGroup>
    </Popover>
  );
};

const ColorPopover: FC<{ canvas: fabric.Canvas | null }> = ({ canvas }) => {
  const {
    state: { selectedObject },
  } = useLabelEditor();
  const [color, setColor] = useState<string | undefined>(
    selectedObject?.fill?.toString()
  );

  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button w="100%" bgColor={color}></Button>
      </PopoverTrigger>
      <PopoverContent w="auto">
        <HexColorPicker
          id="color-picker"
          color={selectedObject?.fill?.toString()}
          onChange={(color) => {
            setColor(color);
            selectedObject?.set({
              fill: color,
            });
            canvas?.renderAll();
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

const FontPopover: FC<{ canvas: fabric.Canvas | null }> = ({ canvas }) => {
  const {
    state: { selectedObject },
  } = useLabelEditor();
  const currentFont = (selectedObject as fabric.Text)?.fontFamily as FontFamily;
  const [fontState, setFontState] = useState<FontFamily>(currentFont);

  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button
          w="100%"
          colorScheme="brand"
          id="font-picker"
          style={ellipsisStyle}
        >
          {currentFont}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="auto">
        <LabelFontPicker
          color={selectedObject?.fill?.toString() || "#000000"}
          width={300}
          text={(selectedObject as fabric.Text).text}
          selected={fontState}
          onChange={(font) => {
            (selectedObject as fabric.Text).set({
              fontFamily: font,
            });
            setFontState(font);
            selectedObject?.setCoords();
            canvas?.renderAll();
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

const RemoveButton: FC<{ canvas: fabric.Canvas | null; isMobile: boolean }> = ({
  canvas,
  isMobile,
}) => {
  const {
    state: { selectedObject },
    setState,
  } = useLabelEditor();

  return (
    <Button
      w={isMobile ? undefined : "100%"}
      borderRadius={0}
      id="remove-button"
      colorScheme="red"
      onClick={() => {
        // remove selected object
        if (selectedObject) {
          canvas?.remove(selectedObject);

          setState((state) => ({
            ...state,
            selectedObject: null,
          }));
        }
      }}
      isDisabled={!selectedObject}
      display="flex"
      alignItems="center"
      leftIcon={
        isMobile ? undefined : (
          <Icon fontSize="xl">
            <BsTrash3 />
          </Icon>
        )
      }
    >
      {isMobile && (
        <Icon fontSize="xl">
          <BsTrash3 />
        </Icon>
      )}
      {!isMobile && <FormattedMessage id="cask.label.editor.remove" />}
    </Button>
  );
};

const TextControls: FC<{ canvas: fabric.Canvas | null }> = ({ canvas }) => {
  const isMobile = useMedia("(max-width: 48em)");

  const {
    state: { selectedObject },
  } = useLabelEditor();

  const setSize = (enlarge: boolean) => {
    if (!selectedObject) return;

    const scaleFactor = enlarge ? 0.1 : -0.1;

    // Get old scaled width & height
    const oldWidth = selectedObject.getScaledWidth();
    const oldHeight = selectedObject.getScaledHeight();

    // Update scale
    selectedObject.set({
      scaleX: selectedObject.scaleX! + scaleFactor,
      scaleY: selectedObject.scaleY! + scaleFactor,
    });

    selectedObject.setCoords(); // Update coords after scaling

    // Get new scaled width & height
    const newWidth = selectedObject.getScaledWidth();
    const newHeight = selectedObject.getScaledHeight();

    // Adjust position to keep the object centered
    selectedObject.set({
      left: selectedObject.left! - (newWidth - oldWidth) / 2,
      top: selectedObject.top! - (newHeight - oldHeight) / 2,
    });

    selectedObject.setCoords(); // Ensure correct rendering
    canvas?.renderAll();
  };

  if (!selectedObject || selectedObject.isType("image")) {
    return null;
  }

  return (
    <span id="object-controls">
      <CustomAlert
        zIndex={1}
        labelKey={"cask.label.editor.help-selected"}
        status="info"
      />
      <Box
        w="100%"
        bgColor="#f2f2f2"
        pb={4}
        px={6}
        display="flex"
        flexWrap={isMobile ? "wrap" : "nowrap"}
      >
        <Box mr={6}>
          <Text>
            <FormattedMessage id="cask.label.editor.help-size" />
          </Text>
          <Box display="flex" alignItems="center" mt={2}>
            <Box>
              <ButtonGroup colorScheme="brand">
                <IconButton
                  id="increase-size"
                  aria-label="+"
                  icon={<MinusIcon />}
                  onClick={() => setSize(false)}
                />
                <IconButton
                  id="decrease-size"
                  aria-label="-"
                  icon={<AddIcon />}
                  onClick={() => setSize(true)}
                />
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
        <Box mr={6}>
          <Text>
            <FormattedMessage id="cask.label.editor.help-color" />
          </Text>
          <Box display="flex" alignItems="center" mt={2}>
            <Box>
              <ColorPopover canvas={canvas} />
            </Box>
          </Box>
        </Box>
        <Box mr={6}>
          <Text>
            <FormattedMessage id="cask.label.editor.help-font" />
          </Text>
          <Box display="flex" alignItems="center" mt={2}>
            <Box>
              <FontPopover canvas={canvas} />
            </Box>
          </Box>
        </Box>
        <Box mr={6} mt={isMobile ? 4 : 0}>
          <Text>
            <FormattedMessage id="cask.label.editor.help-text" />
          </Text>
          <Box display="flex" alignItems="center" mt={2}>
            <Box>
              <TextPopover canvas={canvas} />
            </Box>
          </Box>
        </Box>
      </Box>
    </span>
  );
};

const ImageSizeSlider: FC<{ canvas: fabric.Canvas | null }> = ({ canvas }) => {
  const {
    state: { selectedObject, sizeError },
    setState,
  } = useLabelEditor();
  const imageSizePercent = (selectedObject?.scaleX || 0) * 100;
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    if (selectedObject) {
      setSliderValue(imageSizePercent);
    }
  }, [selectedObject, imageSizePercent]);

  useEffect(() => {
    if (selectedObject) {
      setSliderValue((selectedObject.scaleX || 0) * 100);
    }
  }, [selectedObject]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      sizeError: !!(selectedObject?.isType("image") && sliderValue === 100),
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue, selectedObject]);

  useEffect(() => {
    if (selectedObject) {
      setSliderValue((selectedObject.scaleX || 0) * 100);
    }
  }, [selectedObject]);

  const handleSliderChange = (v: number) => {
    if (!selectedObject) return;

    setSliderValue(v);

    // Get center position before scaling
    const centerX =
      selectedObject.left! +
      (selectedObject.width! * selectedObject.scaleX!) / 2;
    const centerY =
      selectedObject.top! +
      (selectedObject.height! * selectedObject.scaleY!) / 2;

    // Update scale
    selectedObject.set({
      scaleX: v / 100,
      scaleY: v / 100,
    });

    // Recalculate position to maintain center
    selectedObject.set({
      left: centerX - (selectedObject.width! * selectedObject.scaleX!) / 2,
      top: centerY - (selectedObject.height! * selectedObject.scaleY!) / 2,
    });

    selectedObject.setCoords();
    canvas?.renderAll();
  };

  if (!selectedObject?.isType("image")) {
    return null;
  }

  return (
    <>
      {sizeError ? (
        <CustomAlert
          w="100%"
          zIndex={1}
          labelKey="cask.label.editor.image.size-error"
          status="warning"
        />
      ) : (
        <CustomAlert
          w="100%"
          zIndex={1}
          labelKey={"cask.label.editor.help-selected-image"}
          status="info"
        />
      )}
      <Box w="100%" bgColor="#f2f2f2" py={2} px={4}>
        <Text>
          <FormattedMessage id="cask.label.editor.help-size" />
        </Text>
        <Slider
          id="slider"
          value={sliderValue}
          aria-label="slider"
          onChange={handleSliderChange}
        >
          <SliderTrack h={2} bg="#BDBDBD">
            <SliderFilledTrack bg="brand.200" />
          </SliderTrack>
          <SliderThumb borderColor="#BDBDBD" h={6} w={6} />
        </Slider>
      </Box>
    </>
  );
};

export const HelperMessage: FC<{ canvas: fabric.Canvas }> = ({ canvas }) => {
  return (
    <>
      <ImageSizeSlider canvas={canvas} />
      <TextControls canvas={canvas} />
    </>
  );
};

export const LabelEditorControls: FC<{
  canvas: fabric.Canvas | null;
  size: {
    width: number;
    height: number;
  };
}> = ({ canvas, size }) => {
  const isMobile = useMedia("(max-width: 48em)");

  return (
    <>
      <Box h="120px" position="sticky" top={isMobile ? "110px" : "100px"}>
        <Box zIndex={1} w="100%" bgColor="gray.200" pos="relative">
          <CustomAlert
            zIndex={1}
            labelKey="cask.label.editor.help"
            status="info"
          />
          <ButtonGroup
            w="100%"
            zIndex={2}
            isAttached
            overflow="hidden"
            bgColor="gray.200"
            gap="1px"
          >
            <LabelImagePicker canvas={canvas} size={size} />
            <LabelTextPicker canvas={canvas} size={size} />
            <RemoveButton canvas={canvas} isMobile={isMobile} />
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};
