import { Box } from "@chakra-ui/react";
import * as fabric from "fabric";
import { useFormikContext } from "formik";
import { FC, useEffect, useRef } from "react";
import "react-fontpicker-ts/dist/index.css";
import { useClickAway } from "react-use";
import { BottlingFormValues } from "../BottlingForm";
import { useLabelEditor } from "./LabelEditorContext";
import { HelperMessage, LabelEditorControls } from "./LabelEditorControls";
const resizeImage: string = require("assets/images/resize.svg").default;

interface LabelEditorProps {
  containerWidth: number;
  labelSize: { height: number; width: number };
  labelOffset: number;
  index: number;
  scaleModifier?: number;
  outerTransfromOrigin?: string;
  controlsBottomOffset?: number;
}

const drawGuideline = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  canvas: fabric.Canvas
) => {
  const guideline = new fabric.Line([x1, y1, x2, y2], {
    stroke: "grey",
    strokeWidth: 2,
    selectable: false,
    evented: false,
    name: "guideline",
  });

  canvas?.add(guideline);
  renderCanvas(canvas);
};

const renderCanvas = (canvas: fabric.Canvas) => {
  requestAnimationFrame(() => canvas?.renderAll());
};

const removeGuidelines = (canvas: fabric.Canvas) => {
  // Remove old guidelines
  canvas?.getObjects().forEach((obj) => {
    if (obj.isType("line")) {
      canvas?.remove(obj);
    }
  });
};

const bringImageToFront = (
  obj: fabric.FabricObject<
    Partial<fabric.FabricObjectProps>,
    fabric.SerializedObjectProps,
    fabric.ObjectEvents
  >,
  canvas: fabric.Canvas
) => {
  if (!obj || !obj.isType("image")) return;

  canvas.bringObjectToFront(obj);
  canvas?.getObjects().forEach((obj) => {
    if (obj.isType("textbox") || obj.isType("i-text") || obj.isType("text")) {
      canvas.bringObjectToFront(obj);
    }
  });
};

const handleObjectMoving = (
  e: fabric.BasicTransformEvent<fabric.TPointerEvent> & {
    target: fabric.FabricObject<
      Partial<fabric.FabricObjectProps>,
      fabric.SerializedObjectProps,
      fabric.ObjectEvents
    >;
  },
  canvas: fabric.Canvas
) => {
  const xCenter = canvas.width! / 2;
  const yCenter = canvas.height! / 2;

  // Get scaled dimensions
  const target = e.target;
  const scaledWidth = target.getScaledWidth();
  const scaledHeight = target.getScaledHeight();

  const xTargetCenter = target.left! + scaledWidth / 2;
  const yTargetCenter = target.top! + scaledHeight / 2;
  const snapDistance = 25;

  removeGuidelines(canvas);

  if (Math.abs(xCenter - xTargetCenter) < snapDistance) {
    target.set({ left: xCenter - scaledWidth / 2 });
    drawGuideline(xCenter, 0, xCenter, canvas.height!, canvas);
  }

  if (Math.abs(yCenter - yTargetCenter) < snapDistance) {
    target.set({ top: yCenter - scaledHeight / 2 });
    drawGuideline(0, yCenter, canvas.width!, yCenter, canvas);
  }

  // Force update coordinates
  target.setCoords();

  // Refresh canvas to remove flickering
  renderCanvas(canvas);
};

const handleSelection = (
  e: Partial<fabric.TEvent<fabric.TPointerEvent>> & {
    selected: fabric.FabricObject<
      Partial<fabric.FabricObjectProps>,
      fabric.SerializedObjectProps,
      fabric.ObjectEvents
    >[];
  },
  canvas: fabric.Canvas
) => {
  const obj = e.selected[0];
  removeGuidelines(canvas);
  if (obj) {
    bringImageToFront(obj, canvas);

    obj.set({
      borderColor: "rgb(84, 0, 149)",
      cornerColor: "rgb(84, 0, 149)",
      cornerSize: 32,
      transparentCorners: false,
      cornerStyle: "circle",
    });

    renderCanvas(canvas);
  }
};

const LabelEditor: FC<LabelEditorProps> = ({
  containerWidth,
  index,
  labelSize,
  labelOffset,
  scaleModifier = 1,
  outerTransfromOrigin = "center",
  controlsBottomOffset = 1,
}) => {
  const { setFieldValue } = useFormikContext<BottlingFormValues>();
  const stageScale = containerWidth / labelSize.width;
  const canvasEl = useRef<HTMLCanvasElement | null>(null);
  const canvas = useRef<fabric.Canvas | null>(null);
  const borderTimeOut = useRef<NodeJS.Timeout | null>(null);
  const bottomOffset =
    (((labelSize.width + labelSize.height + labelOffset) * stageScale) / 2) *
    0.4;
  const {
    state: { selectedObject, movingOrScaling },
    setState,
  } = useLabelEditor();
  const border = movingOrScaling
    ? "1px dashed rgb(162, 162, 162)"
    : "1px solid transparent";

  useEffect(() => {
    setFieldValue(
      `labels.labels[${index}].touched`,
      !!canvas.current?.getObjects().length
    );
  }, [selectedObject]);

  useEffect(() => {
    if (canvasEl.current === null) return;

    const imgElement = new Image();
    imgElement.src = resizeImage.toString();
    console.log(imgElement);
    imgElement.onload = () => {
      setState((state) => ({
        ...state,
        resizeImage: imgElement,
      }));
    };

    canvas.current = new fabric.Canvas(canvasEl.current, {
      selection: true, // Enable selection
      preserveObjectStacking: true,
      enableRetinaScaling: false,
    });

    // Enable object selection and transformation
    canvas.current?.on("selection:created", (e) => {
      handleSelection(e, canvas.current!);
      setState((state) => ({
        ...state,
        selectedObject: e.selected[0],
      }));
    });
    canvas.current?.on("selection:updated", (e) => {
      handleSelection(e, canvas.current!);
      setState((state) => ({
        ...state,
        selectedObject: e.selected[0],
      }));
    });
    canvas.current?.on("selection:cleared", () => {
      removeGuidelines(canvas.current!);
      setState((state) => ({
        ...state,
        selectedObject: null,
      }));
    });

    canvas.current?.on("object:moving", (e) => {
      handleObjectMoving(e, canvas.current!);
      setState((state) => ({
        ...state,
        movingOrScaling: true,
      }));

      if (borderTimeOut.current) {
        clearTimeout(borderTimeOut.current);
      }

      borderTimeOut.current = setTimeout(() => {
        setState((state) => ({
          ...state,
          movingOrScaling: false,
        }));
      }, 500);
    });

    canvas.current?.on("object:scaling", (e) => {
      const obj = e.target;
      if (obj) {
        setState((state) => ({
          ...state,
          sizeError: false,
        }));

        if (obj.isType("image") && (obj.scaleX > 1 || obj.scaleY > 1)) {
          // visa varning
          obj.set({
            scaleX: 1,
            scaleY: 1,
          });

          setState((state) => ({
            ...state,
            sizeError: true,
          }));

          return;
        }

        setState((state) => ({
          ...state,
          movingOrScaling: true,
        }));

        if (borderTimeOut.current) {
          clearTimeout(borderTimeOut.current);
        }

        borderTimeOut.current = setTimeout(() => {
          setState((state) => ({
            ...state,
            movingOrScaling: false,
          }));
        }, 500);
      }
    });

    return () => {
      canvas.current?.dispose();
    };
  }, []);

  useClickAway(canvasEl, (e) => {
    if (!canvas.current || !selectedObject) return;
    // check if target has class "upper-canvas"'
    if (
      !(e.target as HTMLElement).classList.contains("upper-canvas") &&
      !(e.target as HTMLElement).closest("#remove-button") &&
      !(e.target as HTMLElement).closest("#slider-track-slider") &&
      !(e.target as HTMLElement).closest("#slider-thumb-slider") &&
      !(e.target as HTMLElement).closest("#increase-size") &&
      !(e.target as HTMLElement).closest("#decrease-size") &&
      !(e.target as HTMLElement).closest("#font-picker") &&
      !(e.target as HTMLElement).closest("#text-edit-button") &&
      !(e.target as HTMLElement).closest("#object-controls") &&
      !(e.target as HTMLElement).closest("#text-input") &&
      !(e.target as HTMLElement).closest("#text-adjust-center") &&
      !(e.target as HTMLElement).closest("#text-adjust-left") &&
      !(e.target as HTMLElement).closest("#text-adjust-right") &&
      !(e.target as HTMLElement).closest("#color-picker") &&
      !(e.target as HTMLElement).id.includes("popover-trigger") &&
      !(e.target as HTMLElement).id.includes("popover-body")
    ) {
      canvas.current?.discardActiveObject();
      renderCanvas(canvas.current);

      setTimeout(() => {
        setState((state) => ({
          ...state,
          selectedObject: null,
        }));
      }, 100);
    }
  });

  return (
    <Box pos="absolute" top={0} left={0} bottom={0} right={0}>
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={bottomOffset + labelSize.height * stageScale + "px"}
      >
        <LabelEditorControls canvas={canvas.current} size={labelSize} />
      </Box>
      <Box
        position="absolute"
        bottom={0}
        display="flex"
        alignItems="top"
        w="100%"
        h={bottomOffset * controlsBottomOffset + "px"}
      >
        <Box w="100%" pos="relative" zIndex={2}>
          <HelperMessage canvas={canvas.current!} />
        </Box>
      </Box>
      <Box
        /* border="1px solid black !important" */
        boxSizing="border-box"
        w={containerWidth}
        h={labelSize.height * stageScale}
        pos="absolute"
        bottom={bottomOffset + "px"}
        overflow={"hidden"}
        border={border}
        transition="border 0.2s"
        style={{
          transform: `scale(${scaleModifier})`,
          transformOrigin: outerTransfromOrigin,
        }}
      >
        <div
          style={{
            transform: `scale(${stageScale})`,
            transformOrigin: "top left",
          }}
        >
          <canvas
            id={`label-canvas-${index}`}
            ref={canvasEl}
            width={labelSize.width}
            height={labelSize.height}
          />
        </div>
      </Box>
    </Box>
  );
};

export default LabelEditor;
