import {
  Button,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { getImagePreview } from "components/FileUploader/FileUploaderPreview";
import * as fabric from "fabric"; // v6
import { FC, useRef } from "react";
import "react-fontpicker-ts/dist/index.css";
import { BsCardImage } from "react-icons/bs";
import { MdUploadFile } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useLabelEditor } from "./LabelEditorContext";

const NUMBER_OF_PRESETS = 9;
const PRESET_IMAGES = Array.from({ length: NUMBER_OF_PRESETS }, (_, i) =>
  require(`assets/images/labels/${i + 1}.webp`)
);

const onFileChanged = async (
  evt: React.SyntheticEvent<HTMLInputElement>,
  setFileUrl: (url: string) => void
) => {
  const target = evt.target as HTMLInputElement;

  if (target.files === null) {
    return;
  }

  const file = target.files[0];

  // make an url from the file
  setFileUrl(await getImagePreview(file));
};

const UploadFileButton: FC<{
  onAdd: (url: string) => void;
}> = ({ onAdd }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Input
        hidden
        ref={inputRef}
        type="file"
        accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
        onChange={(e) => onFileChanged(e, onAdd)}
      />
      <Button
        w="100%"
        onClick={() => inputRef.current?.click()}
        mt={6}
        colorScheme="brand"
        leftIcon={<MdUploadFile />}
      >
        <FormattedMessage id="cask.orders.bottling.labels.customization.file.desc" />
      </Button>
    </>
  );
};

const PresetImages: FC<{ onAdd: (url: string) => void }> = ({ onAdd }) => {
  return (
    <SimpleGrid columns={3} spacing={4} width="100%" height="300px">
      {PRESET_IMAGES.map((url) => (
        <Button
          key={url}
          p={0}
          borderRadius={4}
          overflow="hidden"
          w="100%"
          h="100%"
          onClick={() => onAdd(url)}
        >
          <Image h="100%" w="100%" objectFit="cover" src={url} />
        </Button>
      ))}
    </SimpleGrid>
  );
};

export const LabelImagePicker: FC<{
  canvas: fabric.Canvas | null;
  size: {
    width: number;
    height: number;
  };
}> = ({ canvas, size }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    state: { resizeImage },
    setState,
  } = useLabelEditor();
  const onAdd = (url: string) => {
    // deselect all objects
    canvas?.discardActiveObject();

    fabric.FabricImage.fromURL(url, {
      crossOrigin: "anonymous",
    }).then((image) => {
      if (image.width > size.width || image.height > size.height) {
        if (image.width > image.height) {
          image.scaleToWidth(size.width);
        } else {
          image.scaleToHeight(size.height);
        }
      }

      image.top = 0;
      image.left = 0;

      image.controls = {
        ...image.controls,
        br: new fabric.Control({
          x: 0.5,
          y: 0.5,
          offsetX: -15,
          offsetY: -15,
          cursorStyle: "se-resize",
          actionHandler: fabric.controlsUtils.scalingEqually,
          render: (
            ctx: CanvasRenderingContext2D,
            left: number,
            top: number,
            fabricObject: fabric.Object
          ) => {
            const size = 60;
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
            ctx.drawImage(resizeImage!, -size / 2, -size / 2, size, size);
            ctx.restore();
          },
        }),
      };

      image.setControlsVisibility({
        tl: false,
        tr: false,
        br: true,
        bl: false,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false,
      });

      canvas?.add(image);

      // Bring all text objects to front
      canvas?.getObjects().forEach((obj) => {
        if (
          obj.isType("textbox") ||
          obj.isType("i-text") ||
          obj.isType("text")
        ) {
          canvas?.bringObjectToFront(obj);
        }
      });

      // set selected object
      canvas?.setActiveObject(image);

      setState((state) => ({
        ...state,
        selectedObject: image,
      }));

      onClose();
    });
  };

  return (
    <>
      <Button
        bgColor="#f2f2f2"
        w="100%"
        onClick={onOpen}
        display="flex"
        alignItems="center"
        leftIcon={
          <Icon fontSize="xl">
            <BsCardImage />
          </Icon>
        }
      >
        <FormattedMessage id="cask.label.editor.image.add" />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="cask.label.editor.image.add" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PresetImages onAdd={onAdd} />
            <UploadFileButton onAdd={onAdd} />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              <FormattedMessage id="common.close" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
