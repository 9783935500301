import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import * as fabric from "fabric"; // v6
import { Formik } from "formik";
import { FC } from "react";
import { HexColorPicker } from "react-colorful";
import "react-fontpicker-ts/dist/index.css";
import { MdOutlineTextFields } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useMeasure } from "react-use";
import { formatToString } from "utils/format";
import { useLabelEditor } from "./LabelEditorContext";
import LabelFontPicker, { FontFamily } from "./LabelFontPicker";

const textInitialValues = {
  text: "",
  font: "IvarDisplay" as FontFamily,
  color: "#000000",
};
export const LabelTextPicker: FC<{
  canvas: fabric.Canvas | null;
  size: {
    width: number;
    height: number;
  };
}> = ({ canvas, size }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    state: { resizeImage },
  } = useLabelEditor();
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const onAdd = (values: typeof textInitialValues) => {
    // deselect all objects
    canvas?.discardActiveObject();

    const maxCanvasWidth = size.width * 0.9; // Keep some padding
    let fontSize = 100;

    // Create temporary text object to measure width
    const tempText = new fabric.Text(values.text, {
      fontSize,
      fontFamily: values.font,
    });

    // Reduce font size until it fits within canvas
    while (tempText.width > maxCanvasWidth && fontSize > 10) {
      fontSize -= 2;
      tempText.set({ fontSize });
      tempText.initDimensions(); // Recalculate width
    }

    // Create actual text object with adjusted size
    const textObj = new fabric.Textbox(values.text, {
      fontSize,
      editable: false,
      selectable: true,
      fontFamily: values.font,
      fill: values.color,
      width: tempText.width, // Ensure it stays on one line
      textAlign: "center",
    });

    // Center text on canvas
    textObj.top = size.height / 2 - textObj.height / 2;
    textObj.left = size.width / 2 - textObj.width / 2;

    textObj.controls = {
      ...textObj.controls,
      r: new fabric.Control({
        cursorStyle: "col-resize",
        actionHandler: fabric.controlsUtils.changeWidth,
        x: 0.5,
        offsetX: -15,
        render: (
          ctx: CanvasRenderingContext2D,
          left: number,
          top: number,
          fabricObject: fabric.Object
        ) => {
          const size = 60;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || -45));
          ctx.drawImage(resizeImage!, -size / 2, -size / 2, size, size);
          ctx.restore();
        },
      }),
      br: new fabric.Control({
        x: 0.5,
        y: 0.5,
        offsetX: -15,
        offsetY: -15,
        cursorStyle: "se-resize",
        actionHandler: fabric.controlsUtils.scalingEqually,
        render: (
          ctx: CanvasRenderingContext2D,
          left: number,
          top: number,
          fabricObject: fabric.Object
        ) => {
          const size = 60;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
          ctx.drawImage(resizeImage!, -size / 2, -size / 2, size, size);
          ctx.restore();
        },
      }),
    };

    textObj.setControlsVisibility({
      tl: false,
      tr: false,
      br: true,
      bl: false,
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: false,
    });

    canvas?.add(textObj);
    canvas?.renderAll();
    // set selected object
    canvas?.setActiveObject(textObj);
  };

  const onSubmit = (values: typeof textInitialValues) => {
    onAdd(values);
    onClose();
  };

  return (
    <>
      <Button
        bgColor="#f2f2f2"
        w="100%"
        onClick={onOpen}
        display="flex"
        alignItems="center"
        leftIcon={
          <Icon fontSize="xl">
            <MdOutlineTextFields />
          </Icon>
        }
      >
        <FormattedMessage id="cask.label.editor.text.add" />
      </Button>

      <Formik initialValues={textInitialValues} onSubmit={onSubmit}>
        {(props) => (
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <FormattedMessage id="cask.label.editor.text.add" />
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box mb={4} ref={ref}>
                  <FormControl>
                    <FormLabel fontWeight={300}>
                      <FormattedMessage id="cask.label.editor.text.input" />
                    </FormLabel>
                    <InputGroup>
                      <Textarea
                        placeholder={formatToString(
                          "cask.label.editor.text.placeholder"
                        )}
                        value={props.values.text}
                        onChange={(e) =>
                          props.setFieldValue("text", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormControl>
                </Box>
                <Box mb={4}>
                  <FormLabel fontWeight={300}>
                    <FormattedMessage id={"cask.label.editor.text.color"} />
                  </FormLabel>
                  <HexColorPicker
                    color={props.values.color}
                    onChange={(color) => props.setFieldValue("color", color)}
                  />
                </Box>
                <Box mb={4}>
                  <FormLabel fontWeight={300}>
                    <FormattedMessage id={"cask.label.editor.text.font"} />
                  </FormLabel>
                  <LabelFontPicker
                    color={props.values.color}
                    width={width}
                    text={
                      props.values.text ||
                      formatToString("cask.label.editor.text.placeholder")
                    }
                    selected={props.values.font}
                    onChange={(font) => props.setFieldValue("font", font)}
                  />
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button
                  isDisabled={!props.values.text}
                  colorScheme="brand"
                  mr={3}
                  onClick={props.submitForm}
                >
                  <FormattedMessage id="cask.label.editor.text.add" />
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  <FormattedMessage id="common.close" />
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Formik>
    </>
  );
};
