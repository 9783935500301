export const fonts = [
  "IvarDisplay",
  "LabGrotesque",
  "Roboto",
  "Montserrat",
  "Lato",
  "Poppins",
  "Roboto Condensed",
  "Inter",
  "Roboto Mono",
  "Oswald",
  "Raleway",
  "Playfair Display",
  "Rubik",
  "Roboto Slab",
  "Nunito",
  "Ubuntu",
  "Merriweather",
  "Kanit",
  "Lora",
  "Mukta",
  "Quicksand",
  "Mulish",
  "Barlow",
  "Heebo",
  "PT Serif",
  "Noto Serif",
  "Titillium Web",
  "Manrope",
  "Karla",
  "Libre Franklin",
  "Inconsolata",
  "Hind Siliguri",
  "Arimo",
  "Libre Baskerville",
  "Nanum Gothic",
  "Dosis",
  "Cairo",
  "Bitter",
  "Bebas Neue",
  "Oxygen",
  "Cabin",
  "Abel",
  "Anton",
  "Dancing Script",
  "Noto Serif JP",
  "Jost",
  "EB Garamond",
  "Barlow Condensed",
  "Signika Negative",
  "Space Grotesk",
  "Teko",
  "Source Code Pro",
  "Hind",
  "Assistant",
  "Crimson Text",
  "Pacifico",
  "Lobster",
  "Prompt",
  "Exo 2",
  "Comfortaa",
  "Fjalla One",
  "Archivo",
  "Maven Pro",
  "Slabo 27px",
  "M PLUS Rounded 1c",
  "Outfit",
  "Varela Round",
  "DM Serif Display",
  "Asap Condensed",
  "IBM Plex Mono",
  "Rajdhani",
  "Saira Condensed",
  "Caveat",
  "Abril Fatface",
  "Overpass",
  "Cormorant Garamond",
  "Arvo",
  "Tajawal",
  "Chakra Petch",
  "Asap",
  "Play",
  "Shadows Into Light",
  "Red Hat Display",
  "Domine",
  "Zilla Slab",
  "Oxanium",
  "Indie Flower",
  "M PLUS 1p",
  "Almarai",
  "Satisfy",
  "Catamaran",
  "Barlow Semi Condensed",
  "Hind Madurai",
  "Lilita One",
  "Black Ops One",
  "Nanum Myeongjo",
  "Questrial",
  "Archivo Black",
  "Yanone Kaffeesatz",
  "Frank Ruhl Libre",
  "Urbanist",
  "IBM Plex Serif",
  "Sarabun",
  "Permanent Marker",
  "Russo One",
  "Signika",
  "Chivo",
  "Orbitron",
  "Acme",
  "Cinzel",
  "Archivo Narrow",
  "Didact Gothic",
  "Rowdies",
  "Figtree",
  "Sora",
  "Vollkorn",
  "Amatic SC",
  "Bree Serif",
  "Exo",
  "Alegreya",
  "Cormorant",
  "Noto Kufi Arabic",
  "Source Serif 4",
  "Montserrat Alternates",
  "Lexend",
  "Righteous",
  "Yantramanav",
  "Courgette",
  "ABeeZee",
  "Tinos",
  "Patua One",
  "Amiri",
  "Kalam",
  "Neuton",
  "Alfa Slab One",
  "Changa",
  "Lobster Two",
  "Cardo",
  "Martel",
  "Great Vibes",
  "Roboto Flex",
  "Noticia Text",
  "Philosopher",
  "Passion One",
  "Noto Serif TC",
  "Spectral",
  "Ubuntu Condensed",
  "Prata",
  "Sawarabi Mincho",
  "El Messiri",
  "Crete Round",
  "Space Mono",
  "Alata",
  "Marcellus",
  "Cantarell",
  "Kaushan Script",
  "Francois One",
  "Yellowtail",
  "Dela Gothic One",
  "Khand",
  "Paytone One",
  "Sawarabi Gothic",
  "Pathway Gothic One",
  "Arsenal",
  "Alice",
  "Sacramento",
  "Be Vietnam Pro",
  "Gothic A1",
  "Staatliches",
  "Mate",
  "Old Standard TT",
  "Concert One",
  "Gruppo",
  "Press Start 2P",
  "Josefin Slab",
  "Bodoni Moda",
  "Saira",
  "Gloria Hallelujah",
  "Zeyada",
  "Rokkitt",
  "Noto Serif KR",
  "Ubuntu Mono",
  "Architects Daughter",
  "Unna",
  "Cookie",
  "Advent Pro",
  "Lexend Deca",
  "Noto Naskh Arabic",
  "Itim",
  "Cuprum",
  "Mate SC",
  "Sen",
  "Gelasio",
  "Carter One",
  "Crimson Pro",
  "Rubik Mono One",
  "Cabin Condensed",
  "Antic Slab",
  "Quattrocento",
  "Secular One",
  "Poiret One",
  "Special Elite",
  "Yeseva One",
  "Tangerine",
  "Mitr",
  "Faustina",
  "Mukta Malar",
  "Jaldi",
  "Luckiest Guy",
  "Caladea",
  "Commissioner",
  "Playfair Display SC",
  "Roboto Serif",
  "Literata",
  "Readex Pro",
  "Vidaloka",
  "Ultra",
  "Handlee",
  "News Cycle",
  "Sanchez",
  "Bai Jamjuree",
  "DM Serif Text",
  "Bangers",
  "Aleo",
  "Unica One",
  "Kosugi Maru",
  "Unbounded",
  "Bungee",
  "Viga",
  "Volkhov",
  "Patrick Hand",
  "Allura",
  "Mada",
  "Neucha",
  "Nanum Gothic Coding",
  "Hind Vadodara",
  "Calistoga",
  "Libre Caslon Text",
  "Baloo 2",
  "Amaranth",
  "Adamina",
  "Zen Kaku Gothic New",
  "Inter Tight",
  "Gudea",
  "Lalezar",
  "Titan One",
  "Parisienne",
  "League Spartan",
  "Red Hat Text",
  "Marck Script",
  "PT Mono",
  "Merienda",
  "Pragati Narrow",
  "Taviraj",
  "Epilogue",
  "Abhaya Libre",
  "Istok Web",
  "Yatra One",
  "Courier Prime",
  "Sriracha",
  "Homemade Apple",
  "Playball",
  "Blinker",
  "Noto Serif Bengali",
  "Londrina Solid",
  "Noto Serif SC",
  "Baskervville",
  "Hammersmith One",
  "Ruda",
  "Monoton",
  "Cousine",
  "Bad Script",
  "BIZ UDPGothic",
  "Damion",
  "Saira Semi Condensed",
  "Fira Mono",
  "Jura",
  "Audiowide",
  "Eczar",
  "Voltaire",
  "Anonymous Pro",
  "Lusitana",
  "Shippori Mincho",
  "Mr Dafoe",
  "Castoro",
  "Newsreader",
  "Fraunces",
  "Alex Brush",
  "Rock Salt",
  "Varela",
  "Noto Serif Devanagari",
  "VT323",
  "Comic Neue",
  "Laila",
  "Mandali",
  "Petrona",
  "Forum",
  "Monda",
  "Six Caps",
  "Ramabhadra",
  "BenchNine",
  "Shrikhand",
  "Pangolin",
  "Nothing You Could Do",
  "Electrolize",
  "Share Tech Mono",
  "Zen Maru Gothic",
  "Niramit",
  "Antonio",
  "Quantico",
  "Khula",
  "Squada One",
  "Karma",
  "Economica",
  "Pridi",
  "Nanum Pen Script",
  "David Libre",
  "Italianno",
  "Leckerli One",
  "Allerta Stencil",
  "Fugaz One",
  "Alef",
  "Chewy",
  "Sorts Mill Goudy",
  "Krub",
  "Pinyon Script",
  "Koulen",
  "Libre Barcode 39",
  "Oleo Script",
  "Cutive Mono",
  "Gilda Display",
  "Sarala",
  "Reenie Beanie",
  "Macondo",
  "Reem Kufi",
  "Basic",
  "Gochi Hand",
  "Actor",
  "Saira Extra Condensed",
  "Rufina",
  "Days One",
  "Alexandria",
  "Palanquin",
  "Syne",
  "Armata",
  "Glegoo",
  "Alatsi",
  "Antic",
  "Lemonada",
  "JetBrains Mono",
  "Playfair",
  "Rammetto One",
  "Trirong",
  "Amita",
  "DM Mono",
  "Berkshire Swash",
  "Julee",
  "Aclonica",
  "Changa One",
  "PT Serif Caption",
  "Sintony",
  "Athiti",
  "Arapey",
  "Vazirmatn",
  "K2D",
  "Libre Bodoni",
  "Charm",
  "Syncopate",
  "Hind Guntur",
  "Scada",
  "Holtwood One SC",
  "Short Stack",
  "GFS Didot",
  "Big Shoulders Display",
  "Quintessential",
  "Palanquin Dark",
  "Markazi Text",
  "Kreon",
  "Just Another Hand",
  "Covered By Your Grace",
  "Yrsa",
  "Cinzel Decorative",
  "Belleza",
  "Sunflower",
  "Michroma",
  "Capriola",
  "Candal",
  "Caveat Brush",
  "Aldrich",
  "Averia Serif Libre",
  "Krona One",
  "Fredericka the Great",
  "Pathway Extreme",
  "Mali",
  "Graduate",
  "STIX Two Text",
  "Bowlby One SC",
  "Coda",
  "Cormorant Infant",
  "M PLUS 1",
  "Cabin Sketch",
  "Boogaloo",
  "Livvic",
  "Zen Old Mincho",
  "Kiwi Maru",
  "Oranienbaum",
  "Marcellus SC",
  "Arizonia",
  "Rozha One",
  "Jua",
  "Arbutus Slab",
  "Carrois Gothic",
  "Shadows Into Light Two",
  "Cantata One",
  "Rubik Moonrocks",
  "Bevan",
  "Atkinson Hyperlegible",
  "Bellefair",
  "Herr Von Muellerhoff",
  "Seaweed Script",
  "Creepster",
  "Rambla",
  "La Belle Aurore",
  "Do Hyeon",
  "Overlock",
  "Niconne",
  "Contrail One",
  "Telex",
  "Allerta",
  "Chonburi",
  "Limelight",
  "Annie Use Your Telescope",
  "Noto Serif Display",
  "Biryani",
  "Grandstander",
  "Pattaya",
  "Knewave",
  "Rancho",
  "Rye",
  "Yesteryear",
  "Marmelad",
  "Pirata One",
  "Norican",
  "Tillana",
  "Fira Code",
  "Lustria",
  "Overpass Mono",
  "Zen Antique",
  "Arya",
  "Corben",
  "Enriqueta",
  "Darker Grotesque",
  "Wallpoet",
  "Podkova",
  "Alike",
  "Hanuman",
  "B612 Mono",
  "BioRhyme",
  "Brygada 1918",
  "Averia Libre",
  "Nanum Brush Script",
  "Mrs Saint Delafield",
  "Kurale",
  "Miriam Libre",
  "Alegreya SC",
  "Hanken Grotesk",
  "Coming Soon",
  "Suez One",
  "Manjari",
  "Kristi",
  "Petit Formal Script",
  "Fanwood Text",
  "Sofia",
  "Baloo Da 2",
  "Bowlby One",
  "Average",
  "Kosugi",
  "Henny Penny",
  "Grand Hotel",
  "Fredoka",
  "Magra",
  "Golos Text",
  "Metrophobic",
  "Caudex",
  "Spinnaker",
  "Georama",
  "Ovo",
  "Kufam",
  "Lateef",
  "Aladin",
  "Monsieur La Doulaise",
  "Mallanna",
  "League Gothic",
  "Cedarville Cursive",
  "Thasadith",
  "Coustard",
  "Noto Nastaliq Urdu",
  "Nobile",
  "Irish Grover",
  "Shippori Mincho B1",
  "Vollkorn SC",
  "Delius",
  "Cormorant Upright",
  "Judson",
  "Jockey One",
  "Grenze Gotisch",
  "Halant",
  "Kameron",
  "Nova Mono",
  "Share",
  "Bungee Inline",
  "Rochester",
  "Sniglet",
  "ZCOOL QingKe HuangYou",
  "Maitree",
  "Trocchi",
  "Spectral SC",
  "Nixie One",
  "IM Fell English SC",
  "Amiko",
  "Freehand",
  "Klee One",
  "Amethysta",
  "Rakkas",
  "Stardos Stencil",
  "Gurajada",
  "Big Shoulders Text",
  "Oxygen Mono",
  "Proza Libre",
  "Turret Road",
  "Rosario",
  "Alike Angular",
  "Tilt Prism",
  "Poller One",
  "NTR",
  "Baloo Tamma 2",
  "B612",
  "Gabriela",
  "Hepta Slab",
  "IM Fell English",
  "Homenaje",
  "Baloo Thambi 2",
  "Love Ya Like A Sister",
  "Calligraffitti",
  "Italiana",
  "Akshar",
  "IM Fell DW Pica",
  "Style Script",
  "Schoolbell",
  "Germania One",
  "Fauna One",
  "Della Respira",
  "Allison",
  "Hahmlet",
  "Aref Ruqaa",
  "Croissant One",
  "UnifrakturMaguntia",
  "Mansalva",
  "Brawler",
  "Qwigley",
  "Tomorrow",
  "Fasthand",
  "Molengo",
  "Libre Barcode 128",
  "Slabo 13px",
  "Fahkwang",
  "Marvel",
  "Bentham",
  "Dawning of a New Day",
  "Goudy Bookletter 1911",
  "Suranna",
  "Headland One",
  "Rasa",
  "Trykker",
  "Sigmar One",
  "Modak",
  "Fresca",
  "Gloock",
  "Cormorant SC",
  "Fondamento",
  "Zen Kaku Gothic Antique",
  "M PLUS 2",
  "Vesper Libre",
  "Oleo Script Swash Caps",
  "Chelsea Market",
  "Fjord One",
  "Federo",
  "Mirza",
  "Waiting for the Sunrise",
  "ZCOOL XiaoWei",
  "Cutive",
  "Cambay",
  "KoHo",
  "Kadwa",
  "Fuggles",
  "Bungee Shade",
  "Silkscreen",
  "Patrick Hand SC",
  "Montserrat Subrayada",
  "Rouge Script",
  "Major Mono Display",
  "Copse",
  "Bellota Text",
  "Inder",
  "Padauk",
  "Zen Kurenaido",
  "Kaisei Decol",
  "Syne Mono",
  "Antic Didone",
  "Baloo Chettan 2",
  "Gugi",
  "Mukta Vaani",
  "Andika",
  "Gayathri",
  "Gravitas One",
  "Kodchasan",
  "Baloo Paaji 2",
  "Timmana",
  "Lexend Zetta",
  "Buenard",
  "RocknRoll One",
  "Aboreto",
  "Goblin One",
  "Ma Shan Zheng",
  "Jomhuria",
  "Quando",
  "Notable",
  "Ibarra Real Nova",
  "Original Surfer",
  "Radley",
  "Raleway Dots",
  "BhuTuka Expanded One",
  "Sedgwick Ave",
  "Tenali Ramakrishna",
  "DotGothic16",
  "BIZ UDPMincho",
  "Sail",
  "Salsa",
  "Amarante",
  "Wix Madefor Display",
  "Mouse Memoirs",
  "Cambo",
  "Yusei Magic",
  "Oregano",
  "Battambang",
  "Glory",
  "Geologica",
  "Asul",
  "Anuphan",
  "Aguafina Script",
  "Supermercado One",
  "Kaisei Opti",
  "Hi Melody",
  "Ms Madi",
  "Hurricane",
  "Kite One",
  "Chivo Mono",
  "Anek Malayalam",
  "Clicker Script",
  "Esteban",
  "Montez",
  "Skranji",
  "Anek Tamil",
  "Allan",
  "Sue Ellen Francisco",
  "Flamenco",
  "Prosto One",
  "Baumans",
  "Rampart One",
  "Mr De Haviland",
  "Give You Glory",
  "Frijole",
  "Akatab",
  "Martian Mono",
  "Inknut Antiqua",
  "Carme",
  "Lemon",
  "Harmattan",
  "Oooh Baby",
  "Bigshot One",
  "Happy Monkey",
  "Ledger",
  "Metamorphous",
  "Ceviche One",
  "Andada Pro",
  "Schibsted Grotesk",
  "Orelega One",
  "Jomolhari",
  "Lexend Exa",
  "Meddon",
  "Mako",
  "Numans",
  "Megrim",
  "Almendra",
  "Eater",
  "Azeret Mono",
  "Saira Stencil One",
  "Elsie",
  "Bakbak One",
  "Pompiere",
  "Lekton",
  "Anaheim",
  "Galada",
  "Dokdo",
  "Reggae One",
  "Xanh Mono",
  "Euphoria Script",
  "MuseoModerno",
  "Libre Caslon Display",
  "Gemunu Libre",
  "Poly",
  "Meera Inimai",
  "Vast Shadow",
  "Ranga",
  "Mountains of Christmas",
  "Wendy One",
  "Shojumaru",
  "Emilys Candy",
  "McLaren",
  "Convergence",
  "Radio Canada",
  "Balthazar",
  "Walter Turncoat",
  "Cormorant Unicase",
  "Goldman",
  "Baloo Bhai 2",
  "Solway",
  "ZCOOL KuaiLe",
  "Mogra",
  "Coiny",
  "Ruslan Display",
  "Noto Serif Malayalam",
  "Red Rose",
  "Geo",
  "Shalimar",
  "Londrina Outline",
  "Finger Paint",
  "Nokora",
  "Scope One",
  "Gaegu",
  "Flow Circular",
  "Delius Unicase",
  "Rubik Dirt",
  "Farro",
  "Crafty Girls",
  "Codystar",
  "Baloo Bhaina 2",
  "Mina",
  "Trade Winds",
  "Bilbo Swash Caps",
  "Bayon",
  "Gluten",
  "Comforter Brush",
  "Over the Rainbow",
  "Kaisei Tokumin",
  "Nova Round",
  "Recursive",
  "Zen Dots",
  "Cherry Cream Soda",
  "Atma",
  "Mukta Mahee",
  "Stint Ultra Condensed",
  "Freckle Face",
  "Doppio One",
  "Chango",
  "Slackey",
  "Charmonman",
  "Montaga",
  "Life Savers",
  "Mochiy Pop One",
  "Sulphur Point",
  "Lexend Peta",
  "Share Tech",
  "Vibur",
  "Dongle",
  "Katibeh",
  "Sonsie One",
  "Potta One",
  "Imprima",
  "Puritan",
  "Orienta",
  "Bellota",
  "Faster One",
  "Baloo Bhaijaan 2",
  "Inria Serif",
  "Belgrano",
  "ADLaM Display",
  "Fragment Mono",
  "Dynalight",
  "Noto Serif Hebrew",
  "Lily Script One",
  "Ephesis",
  "Peralta",
  "Prociono",
  "Poltawski Nowy",
  "Besley",
  "Kotta One",
  "Kranky",
  "Sarpanch",
  "Birthstone",
  "Gantari",
  "Noto Serif Thai",
  "Chau Philomene One",
  "IM Fell French Canon",
  "Port Lligat Slab",
  "Familjen Grotesk",
  "Sumana",
  "Just Me Again Down Here",
  "Square Peg",
  "Loved by the King",
  "Sree Krushnadevaraya",
  "Lovers Quarrel",
  "Cherry Swash",
  "Galdeano",
  "Medula One",
  "Tienne",
  "Libre Barcode 39 Text",
  "Delius Swash Caps",
  "Ranchers",
  "Corinthia",
  "Young Serif",
  "Miniver",
  "Ribeye",
  "Asar",
  "Gafata",
  "Spicy Rice",
  "Pavanam",
  "Macondo Swash Caps",
  "IM Fell Double Pica",
  "Road Rage",
  "Sancreek",
  "Artifika",
  "League Script",
  "Tilt Warp",
  "Qwitcher Grypen",
  "Strait",
  "Gamja Flower",
  "Uncial Antiqua",
  "Kelly Slab",
  "Stick",
  "Tauri",
  "Hina Mincho",
  "Voces",
  "Hachi Maru Pop",
  "BIZ UDGothic",
  "Carrois Gothic SC",
  "Bahiana",
  "Anek Telugu",
  "Iceland",
  "Gabarito",
  "Libre Barcode 39 Extended Text",
  "Gotu",
  "Manuale",
  "UnifrakturCook",
  "Wire One",
  "Scheherazade New",
  "Rosarivo",
  "Varta",
  "Akaya Telivigala",
  "Alkalami",
  "Tiro Devanagari Hindi",
  "Rum Raisin",
  "Sura",
  "Akronim",
  "Song Myung",
  "Unkempt",
  "Cantora One",
  "Murecho",
  "Montagu Slab",
  "Mystery Quest",
  "Abyssinica SIL",
  "The Girl Next Door",
  "Denk One",
  "Edu SA Beginner",
  "Monofett",
  "Overlock SC",
  "Nerko One",
  "Zilla Slab Highlight",
  "Fontdiner Swanky",
  "Instrument Serif",
  "Suwannaphum",
  "Ramaraja",
  "Mochiy Pop P One",
  "Stylish",
  "Yuji Syuku",
  "East Sea Dokdo",
  "Shanti",
  "Gorditas",
  "Cute Font",
  "Jolly Lodger",
  "Ruthie",
  "Chicle",
  "Milonga",
  "Rationale",
  "Meow Script",
  "Kulim Park",
  "Farsan",
  "Nova Square",
  "Donegal One",
  "Vampiro One",
  "Autour One",
  "Moon Dance",
  "Gowun Batang",
  "Akaya Kanadaka",
  "Vujahday Script",
  "Gowun Dodum",
  "Barrio",
  "Arima",
  "Moul",
  "Bona Nova",
  "Iceberg",
  "Meie Script",
  "Engagement",
  "Habibi",
  "Princess Sofia",
  "Srisakdi",
  "Noto Serif Georgian",
  "Anek Devanagari",
  "Noto Serif Telugu",
  "Sahitya",
  "Baloo Tammudu 2",
  "Paprika",
  "Dekko",
  "Stoke",
  "Joan",
  "Rhodium Libre",
  "Redressed",
  "Yomogi",
  "MedievalSharp",
  "Tulpen One",
  "Phudu",
  "Bubbler One",
  "Stalemate",
  "Red Hat Mono",
  "MonteCarlo",
  "Offside",
  "Wellfleet",
  "Kantumruy Pro",
  "Anek Latin",
  "Cagliostro",
  "Beth Ellen",
  "Shippori Antique",
  "WindSong",
  "Lexend Mega",
  "Bruno Ace SC",
  "Dorsa",
  "Unlock",
  "Margarine",
  "Underdog",
  "Khmer",
  "Nova Flat",
  "Zhi Mang Xing",
  "Arbutus",
  "Anek Bangla",
  "Angkor",
  "Snippet",
  "Crushed",
  "Tiro Bangla",
  "Eagle Lake",
  "Train One",
  "Sarina",
  "Karantina",
  "Barriecito",
  "Lexend Giga",
  "Fenix",
  "Libre Barcode 128 Text",
  "Girassol",
  "Kaisei HarunoUmi",
  "Ravi Prakash",
  "Peddana",
  "Text Me One",
  "Chathura",
  "Molle",
  "New Rocker",
  "IM Fell Great Primer",
  "Mooli",
  "Stalinist One",
  "Content",
  "Trispace",
  "Carattere",
  "Poor Story",
  "Grape Nuts",
  "Smokum",
  "Metal Mania",
  "Beau Rivage",
  "REM",
  "Nuosu SIL",
  "Buda",
  "The Nautigal",
  "Kavivanar",
  "Shippori Antique B1",
  "Simonetta",
  "Modern Antiqua",
  "Sedgwick Ave Display",
  "Linden Hill",
  "Comforter",
  "Griffy",
  "Nosifer",
  "Caesar Dressing",
  "Tiro Kannada",
  "Keania One",
  "New Tegomin",
  "Piazzolla",
  "Fascinate Inline",
  "Spirax",
  "Fuzzy Bubbles",
  "Texturina",
  "Condiment",
  "Atomic Age",
  "Waterfall",
  "Long Cang",
  "Licorice",
  "Piedra",
  "Chela One",
  "Mrs Sheppards",
  "Passions Conflict",
  "Redacted",
  "Zen Antique Soft",
  "Onest",
  "Inika",
  "Inspiration",
  "Bilbo",
  "Fascinate",
  "Liu Jian Mao Cao",
  "Jacques Francois Shadow",
  "Kavoon",
  "Averia Gruesa Libre",
  "Stint Ultra Expanded",
  "Mohave",
  "Diplomata",
  "IM Fell French Canon SC",
  "Imbue",
  "Kirang Haerang",
  "Sirin Stencil",
  "Noto Serif HK",
  "Maiden Orange",
  "Viaoda Libre",
  "Wix Madefor Text",
  "Elsie Swash Caps",
  "Jacques Francois",
  "Joti One",
  "Anybody",
  "Kumar One",
  "Junge",
  "Felipa",
  "Ribeye Marrow",
  "Ruluko",
  "Genos",
  "Swanky and Moo Moo",
  "Londrina Shadow",
  "Gulzar",
  "IM Fell DW Pica SC",
  "Yaldevi",
  "Labrada",
  "Lancelot",
  "Tektur",
  "Birthstone Bounce",
  "Whisper",
  "Risque",
  "Ewert",
  "Siemreap",
  "Benne",
  "Marko One",
  "Romanesco",
  "Combo",
  "BIZ UDMincho",
  "Festive",
  "Englebert",
  "M PLUS 1 Code",
  "Stick No Bills",
  "Big Shoulders Stencil Display",
  "Alkatra",
  "Borel",
  "Glass Antiqua",
  "Grenze",
  "Tiro Gurmukhi",
  "Chilanka",
  "Aoboshi One",
  "Jim Nightshade",
  "Odor Mean Chey",
  "Smooch",
  "Belanosima",
  "Almendra SC",
  "Dangrek",
  "Noto Serif Armenian",
  "Bricolage Grotesque",
  "Seymour One",
  "Black And White Picture",
  "Emblema One",
  "Yeon Sung",
  "Kumar One Outline",
  "Anek Gujarati",
  "Zen Tokyo Zoo",
  "Comme",
  "Bungee Hairline",
  "Charis SIL",
  "Noto Serif NP Hmong",
  "Noto Serif Kannada",
  "Oldenburg",
  "Bungee Outline",
  "Gidugu",
  "Hanalei Fill",
  "Galindo",
  "Big Shoulders Stencil Text",
  "Sono",
  "Praise",
  "Qahiri",
  "Braah One",
  "Gupter",
  "Delicious Handrawn",
  "Plaster",
  "Monomaniac One",
  "Erica One",
  "DynaPuff",
  "IM Fell Great Primer SC",
  "Lakki Reddy",
  "Sunshiney",
  "Bokor",
  "Amiri Quran",
  "Kenia",
  "Caprasimo",
  "Kdam Thmor Pro",
  "Water Brush",
  "Ruge Boogie",
  "Dr Sugiyama",
  "Diplomata SC",
  "Smythe",
  "Passero One",
  "Preahvihear",
  "Agdasima",
  "Vibes",
  "Flavors",
  "Gentium Plus",
  "Nabla",
  "Revalia",
  "Caramel",
  "Metal",
  "GFS Neohellenic",
  "Devonshire",
  "Solitreo",
  "Neonderthaw",
  "Mea Culpa",
  "Bonheur Royale",
  "Almendra Display",
  "Single Day",
  "Lacquer",
  "Lexend Tera",
  "Federant",
  "Tilt Neon",
  "Libre Barcode 39 Extended",
  "Luxurious Roman",
  "Bigelow Rules",
  "Bungee Spice",
  "Nova Slim",
  "Luxurious Script",
  "BioRhyme Expanded",
  "Rubik Puddles",
  "Bahianita",
  "Asset",
  "Mynerve",
  "Rubik Wet Paint",
  "Babylonica",
  "Foldit",
  "Butterfly Kids",
  "Carlito",
  "Lavishly Yours",
  "Langar",
  "Aubrey",
  "Ysabeau Infant",
  "Trochut",
  "Imperial Script",
  "Rubik Glitch",
  "Island Moments",
  "IM Fell Double Pica SC",
  "Ballet",
  "Geostar Fill",
  "Sofadi One",
  "Handjet",
  "Mr Bedfort",
  "Rubik Vinyl",
  "Purple Purse",
  "Rubik Beastly",
  "Miss Fajardose",
  "Gentium Book Plus",
  "Zen Loop",
  "Tiro Devanagari Marathi",
  "Noto Serif Khmer",
  "Yuji Boku",
  "Nova Oval",
  "Ysabeau",
  "Astloch",
  "Koh Santepheap",
  "Chenla",
  "Suravaram",
  "Butcherman",
  "Big Shoulders Inline Text",
  "Truculenta",
  "Gideon Roman",
  "Noto Serif Vithkuqi",
  "Libre Barcode EAN13 Text",
  "Hubballi",
  "Taprom",
  "Rubik Distressed",
  "Tourney",
  "Gwendolyn",
  "Nova Cut",
  "Noto Serif Gujarati",
  "Anek Gurmukhi",
  "Rubik Iso",
  "Sassy Frass",
  "Aref Ruqaa Ink",
  "Londrina Sketch",
  "Snowburst One",
  "Fruktur",
  "Dhurjati",
  "Edu TAS Beginner",
  "Noto Serif Tangut",
  "Anek Kannada",
  "Moulpali",
  "Ysabeau Office",
  "Noto Serif Sinhala",
  "Marhey",
  "Fleur De Leah",
  "Nova Script",
  "Anek Odia",
  "Miltonian Tattoo",
  "Climate Crisis",
  "Geostar",
  "Sevillana",
  "Cairo Play",
  "Darumadrop One",
  "Tai Heritage Pro",
  "Edu NSW ACT Foundation",
  "Tiro Tamil",
  "Send Flowers",
  "Oi",
  "Miltonian",
  "Bonbon",
  "Blaka",
  "Finlandica",
  "Updock",
  "Rubik Bubbles",
  "My Soul",
  "Edu QLD Beginner",
  "Edu VIC WA NT Beginner",
  "Big Shoulders Inline Display",
  "Uchen",
  "Lumanosimo",
  "Rubik Spray Paint",
  "Victor Mono",
  "Rubik 80s Fade",
  "Ruwudu",
  "Yuji Mai",
  "Sigmar",
  "Bagel Fat One",
  "Bacasime Antique",
  "Cherry Bomb One",
  "Splash",
  "Reem Kufi Fun",
  "Tiro Telugu",
  "Noto Music",
  "Castoro Titling",
  "Reem Kufi Ink",
  "Noto Serif Myanmar",
  "Gajraj One",
  "Kolker Brush",
  "Noto Serif Ethiopic",
  "Noto Rashi Hebrew",
  "Petemoss",
  "Bruno Ace",
  "Explora",
  "Rubik Pixels",
  "Syne Tactile",
  "Noto Serif Tibetan",
  "Palette Mosaic",
  "Noto Serif Tamil",
  "Lunasima",
  "Redacted Script",
  "Grechen Fuemen",
  "Rubik Storm",
  "Lisu Bosa",
  "Dai Banna SIL",
  "Konkhmer Sleokchher",
  "Ysabeau SC",
  "Noto Serif Lao",
  "Noto Serif Ottoman Siyaq",
  "Warnes",
  "Tapestry",
  "Kablammo",
  "Are You Serious",
  "Grey Qo",
  "Hanalei",
  "Rubik Microbe",
  "Love Light",
  "Wavefont",
  "Blaka Hollow",
  "Estonia",
  "Orbit",
  "Flow Block",
  "Tsukimi Rounded",
  "Moirai One",
  "Twinkle Star",
  "Flow Rounded",
  "Diphylleia",
  "Gasoek One",
  "Noto Traditional Nushu",
  "Cherish",
  "Kings",
  "Lugrasimo",
  "Blaka Ink",
  "Slackside One",
  "Noto Serif Khojki",
  "Grandiflora One",
  "M PLUS Code Latin",
  "Noto Serif Ahom",
  "Moo Lah Lah",
  "Yuji Hentaigana Akari",
  "Shizuru",
  "Rubik Gemstones",
  "Ole",
  "Mingzat",
  "Noto Serif Makasar",
  "Noto Serif Toto",
  "Rubik Marker Hatch",
  "Puppies Play",
  "Noto Serif Khitan Small Script",
  "Rock 3D",
  "Rubik Burned",
  "Narnoor",
  "Ingrid Darling",
  "Rubik Maze",
  "Noto Serif Gurmukhi",
  "Chokokutai",
  "Noto Serif Balinese",
  "Yuji Hentaigana Akebono",
  "Noto Serif Yezidi",
  "Noto Serif Grantha",
  "Padyakke Expanded One",
  "Noto Serif Oriya",
  "Noto Serif Dogra",
] as const;
