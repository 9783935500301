import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FontPicker from "react-fontpicker-ts";
import { List } from "react-virtualized";
import { fonts } from "./fonts";

function isLightColor(hexColor: string): boolean {
  hexColor = hexColor.replace(/^#/, "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness > 127;
}

export type FontFamily = (typeof fonts)[number];
const visibleItems = 3;
const itemHeight = 60;
const listHeight = visibleItems * itemHeight;

const renderRow = ({
  index,
  style,
  text,
  onChange,
  selected,
  color,
}: {
  selected: FontFamily;
  index: number;
  style: React.CSSProperties;
  text: string;
  onChange: (fontFamily: FontFamily) => void;
  color: string;
}) => {
  const fontFamily = fonts[index];
  return (
    <Box style={style} key={index}>
      <Button
        borderRadius={0}
        isActive={selected === fontFamily}
        bgColor={isLightColor(color) ? "blackAlpha.700" : "white"}
        _hover={{
          bgColor: isLightColor(color) ? "blackAlpha.800" : "blackAlpha.100",
        }}
        _active={{
          bgColor: isLightColor(color) ? "blackAlpha.800" : "blackAlpha.200",
        }}
        onClick={() => onChange(fontFamily)}
        display="flex"
        alignItems="center"
        w="100%"
        h="100%"
        justifyContent="center"
      >
        <Box>
          <Box
            color={color}
            fontSize="2xl"
            lineHeight="1"
            style={{ fontFamily }}
          >
            {text}
          </Box>
          <Box color={color}>{fontFamily}</Box>
        </Box>
      </Button>
    </Box>
  );
};

const excludedFonts = ["IvarDisplay", "LabGrotesque"];

const LabelFontPicker: React.FC<{
  onChange: (fontFamily: FontFamily) => void;
  text: string;
  selected: FontFamily;
  width: number;
  color: string;
}> = ({ onChange, text, selected, width, color }) => {
  const [initalOffset, setInitalOffset] = useState<number>(0);
  const [loadedFonts, setLoadedFonts] = useState<string[]>(
    fonts.slice(0, visibleItems).filter((font) => !excludedFonts.includes(font))
  );

  useEffect(() => {
    const fontIndex = fonts.indexOf(selected);
    if (fontIndex >= 0) {
      setInitalOffset(fontIndex * itemHeight);
    }
  }, [selected]);

  const onScroll = ({ overscanStartIndex, overscanStopIndex }: any) => {
    for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
      const font = fonts[i];
      if (!excludedFonts.includes(font) && !loadedFonts.includes(font)) {
        setLoadedFonts([...loadedFonts, font]);
      }
    }
  };

  return (
    <>
      <FontPicker loadFonts={loadedFonts} loaderOnly />
      <Box
        height={listHeight}
        width={width}
        border="1px solid rgb(189, 189, 189)"
        borderRadius="md"
        id="font-picker"
      >
        <List
          height={listHeight}
          width={width}
          rowCount={fonts.length}
          rowHeight={itemHeight}
          rowRenderer={({ index, key, style }) =>
            renderRow({ index, style, text, onChange, selected, color })
          }
          overscanRowCount={visibleItems}
          scrollToIndex={Math.floor(initalOffset / itemHeight)}
          onRowsRendered={onScroll}
        />
      </Box>
    </>
  );
};

export default LabelFontPicker;
