import {
  Box,
  Collapse,
  Divider,
  Heading,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import BrandButton from "components/BrandButton";
import CustomAlert from "components/CustomAlert";
import { useFormikContext } from "formik";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import { formatToString } from "utils/format";
import { BottlingFormValues } from "./BottlingForm";
import { getRefillLabel } from "./BottlingInfoFormPart";
import LabelPreview from "./label-editor/LabelPreview";

const getDeliverLocationKey = (location: DeliveryOccasionLocation) => {
  switch (location) {
    case DeliveryOccasionLocation.Sweden:
      return "cask.orders.bottling.delivery.location.sweden.desc";
    case DeliveryOccasionLocation.Denmark:
      return "cask.orders.bottling.delivery.location.denmark.desc";
    case DeliveryOccasionLocation.Germany:
      return "cask.orders.bottling.delivery.location.germany.desc";
    case DeliveryOccasionLocation.Estonia:
      return "cask.orders.bottling.delivery.location.estonia.desc";

    default:
      return "cask.orders.bottling.delivery.location.sweden.desc";
  }
};

const BottlingSummary = () => {
  const { cask } = useCurrentCask();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { values, setFieldValue, isSubmitting, submitForm } =
    useFormikContext<BottlingFormValues>();
  const isMobile = useMedia("(max-width: 48em)");
  const isGin = cask?.recipe?.id === 3;
  const onBack = () => {
    setIsSubmitted(false);
    setFieldValue("showSummary", false);
  };

  useEffect(() => {
    // Add a fake history event so that the back button does nothing if pressed once
    window.history.pushState(
      "fake-route",
      document.title,
      window.location.href
    );

    window.addEventListener("popstate", onBack);

    // Here is the cleanup when this component unmounts
    return () => {
      window.removeEventListener("popstate", onBack);
      // If we left without using the back button, aka by using a button on the page, we need to clear out that fake history event
      if (window.history.state === "fake-route") {
        window.history.back();
      }
    };
  }, []);

  return (
    <Box id="summary" mt={6}>
      <Heading size="lg" fontWeight={500} mb={4}>
        <FormattedMessage id="cask.orders.bottling.summary.title" />
      </Heading>
      <Divider mt={2} />
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="orders.bottlingOrder.confirmation.bankAccountNumber.label" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {values.loan.isSwedishBankAccount
            ? `${values.loan.clearingNumber} - ${values.loan.swedishBankAccountNumber}`
            : values.loan.foreignBankAccountNumber}
        </Text>
      </Box>
      <Divider mt={4} />
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="orders.bottlingOrder.bottlingAmount.label" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {values.bottlingInfo.fullBottling
            ? formatToString("cask.orders.bottling.info.amount.whole.desc")
            : formatToString("cask.orders.bottling.info.amount.part.bottles", {
                amount: values.bottlingInfo.numberOfBottles || 0,
              })}
        </Text>
      </Box>
      {values.bottlingInfo.refillAction && (
        <Box mt={4}>
          <Text fontWeight={300}>
            <FormattedMessage id="cask.orders.bottling.info.after.label" />
          </Text>
          <Text fontWeight={400} fontSize="lg" mt={1}>
            {values.bottlingInfo.refillAction === "REFILL" &&
              `${formatToString("cask.orders.bottling.info.after.refill")} `}
            {formatToString(
              getRefillLabel(Number(values.bottlingInfo.afterBottling!))
            )}
          </Text>
        </Box>
      )}
      {values.bottlingInfo.refillAction === "REFILL" && (
        <Box mt={4}>
          <Text fontWeight={300}>
            <FormattedMessage id="orders.bottlingOrder.newCaskText.label" />
          </Text>
          <Text fontWeight={400} fontSize="lg" mt={1}>
            {values.bottlingInfo.caskSignText}
          </Text>
        </Box>
      )}
      {!isGin && (
        <Box mt={4}>
          <Text fontWeight={300}>
            <FormattedMessage id="orders.bottlingOrder.useDefaultAlcoholContent.label" />
          </Text>
          <Text fontWeight={400} fontSize="lg" mt={1}>
            {values.bottlingInfo.dialute
              ? `${values.bottlingInfo.alcoholContent}%`
              : formatToString(
                  "orders.bottlingOrder.useDefaultAlcoholContent.true"
                )}
          </Text>
        </Box>
      )}
      <Divider mt={4} />
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="orders.bottlingOrder.date.label" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {values.delivery.bottlingDeliveryOccasion?.name}
        </Text>
      </Box>
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="cask.orders.bottling.delivery.location" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {formatToString(
            getDeliverLocationKey(Number(values.delivery.deliveryLocation!))
          )}
        </Text>
      </Box>
      <Divider mt={4} />
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="cask.orders.bottling.contact.fullname" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {values.contact.fullName}
        </Text>
      </Box>
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="common.email" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {values.contact.email}
        </Text>
      </Box>
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="common.phone-number" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {values.contact.phoneNumber}
        </Text>
      </Box>
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="cask.orders.bottling.contact.comment-title" />
        </Text>
        <Text fontWeight={400} fontSize="lg" mt={1}>
          {values.contact.comment}
        </Text>
      </Box>
      <Divider mt={4} />
      <Box mt={4}>
        <Text fontWeight={300}>
          <FormattedMessage
            id={
              values.labels.labels.length > 1
                ? "cask.orders.bottling.summary.labels.plural"
                : "cask.orders.bottling.labels.title"
            }
          />
        </Text>
        <Wrap mt={2}>
          {values.labels.labels.map((l, i) => (
            <WrapItem key={`label-${i}`} maxW={isMobile ? "40%" : "32%"}>
              <Box
                h="full"
                display="flex"
                flexDir="column"
                justifyContent="space-between"
              >
                <LabelPreview label={l} index={i} summary />
                {values.labels.labels.length > 1 && (
                  <Text
                    mt={2}
                    fontWeight={400}
                    fontSize="lg"
                  >{`${formatToString(
                    "cask.orders.bottling.summary.labels.percent"
                  )} ${l.percentOfLabels} %`}</Text>
                )}
              </Box>
            </WrapItem>
          ))}
        </Wrap>
      </Box>
      <Collapse in={isSubmitted && values.submitError}>
        <Box mt={8} mb={-4}>
          <Box mb={4}>
            <CustomAlert
              status="error"
              labelKey="orders.bottlingOrder.error.unknon"
            />
          </Box>
        </Box>
      </Collapse>
      <Box mt={4}>
        <BrandButton
          large
          mt={8}
          colorScheme="brand"
          w="full"
          size="lg"
          isLoading={isSubmitting}
          onClick={async () => {
            setIsSubmitted(false);
            await submitForm();
            setIsSubmitted(true);
          }}
        >
          <FormattedMessage id="cask.orders.bottling.summary.confirm" />
        </BrandButton>
        <BrandButton
          large
          mt={4}
          leftIcon={<FiArrowLeft size="1.25rem" />}
          borderColor="#807F7F"
          variant="outline"
          onClick={onBack}
          w="full"
          size="lg"
        >
          <FormattedMessage id="cask.orders.bottling.summary.edit" />
        </BrandButton>
      </Box>
    </Box>
  );
};

export default BottlingSummary;
