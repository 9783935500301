import { DeliveryOccasionLocation } from "models/delivery-occasion";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Wrapper = styled.div<{ isOpen: boolean | undefined }>`
  color: ${(props: { isOpen: boolean | undefined }) =>
    props.isOpen ? "#606161" : "#929292"};
`;

const Top = styled.div<{ isOpen: boolean | undefined }>`
  padding: 10px 15px;
  background: ${(props: { isOpen: boolean | undefined }) =>
    props.isOpen ? "#ebedf1" : "none"};
  display: flex;
  align-items: center;
  &:hover {
    background: #ebedf1;
    cursor: pointer;
  }
`;

const OpenButton = styled.div`
  display: flex;
  align-items: center;
`;

const Lower = styled.div`
  border-top: 1px solid #ebedf1;
`;

const PartPrice = styled.div`
  display: flex;
  padding: 10px 14px;
  border-left: 1px solid #ebedf1;
  border-right: 1px solid #ebedf1;
  border-bottom: 1px solid #ebedf1;
`;

const PartPriceLabel = styled.div`
  flex: 1 1 0px;
`;

const PriceSumLabel = styled.div`
  margin-left: 30px;
  flex: 1 1 0px;
`;

const CountryLabel = styled.div`
  width: 80px;
`;

interface Props {
  isOpen: boolean | undefined;
  country: DeliveryOccasionLocation;
  countryName: string;
  net: number;
  exchangeRate: number;
  alcoholDuty: number;
  vendorCharge: number;
  vatRate: number;
  vat: number;
  sum: number;
  onCountryClick: () => void;
}

const BottlePriceListItem: React.FC<Props> = (props) => (
  <Wrapper isOpen={props.isOpen}>
    <Top isOpen={props.isOpen} onClick={props.onCountryClick}>
      <CountryLabel>
        <FormattedMessage id={props.countryName} />
      </CountryLabel>
      <PriceSumLabel>
        {props.sum}kr/
        <FormattedMessage id="orders.bottlingOrder.priceExample.bottleLabel" />
      </PriceSumLabel>
      <OpenButton>
        {props.isOpen ? (
          <FormattedMessage id="orders.bottlingOrder.priceExample.openButtonOpen" />
        ) : (
          <FormattedMessage id="orders.bottlingOrder.priceExample.openButtonClosed" />
        )}
        {props.isOpen ? (
          <i className="material-icons">arrow_drop_up</i>
        ) : (
          <i className="material-icons">arrow_drop_down</i>
        )}
      </OpenButton>
    </Top>
    {props.isOpen && (
      <Lower>
        <PartPrice>
          <PartPriceLabel>
            <FormattedMessage id="orders.bottlingOrder.priceExample.netPrice" />
          </PartPriceLabel>
          {props.net} SEK
        </PartPrice>
        <PartPrice>
          <PartPriceLabel>
            <FormattedMessage id="orders.bottlingOrder.priceExample.alcoholDuty" />
          </PartPriceLabel>
          {props.alcoholDuty} SEK
        </PartPrice>
        <PartPrice>
          <PartPriceLabel>
            <FormattedMessage id="orders.bottlingOrder.priceExample.vendorCharge" />
          </PartPriceLabel>
          {props.vendorCharge} SEK
        </PartPrice>
        <PartPrice>
          <PartPriceLabel>
            <FormattedMessage id="orders.bottlingOrder.priceExample.vat" />
            {` ${props.vatRate * 100}`}%
          </PartPriceLabel>
          {props.vat} SEK
        </PartPrice>
        {!(props.country === DeliveryOccasionLocation.Sweden) && (
          <PartPrice>
            <PartPriceLabel>
              <FormattedMessage id="orders.bottlingOrder.priceExample.exchangeRate" />
            </PartPriceLabel>
            {props.exchangeRate}
          </PartPrice>
        )}
      </Lower>
    )}
  </Wrapper>
);

export default BottlePriceListItem;
