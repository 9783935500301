import { Box, Collapse } from "@chakra-ui/react";
import BrandButton from "components/BrandButton";
import CustomAlert from "components/CustomAlert";
import {
  getImageMetaData,
  getImagePreview,
} from "components/FileUploader/FileUploaderPreview";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { observer } from "mobx-react";
import BottleLabel, { Customization, UploadFile } from "models/bottle-label";
import {
  AfterBottlingAction,
  BankAccountType,
  BottlingAmount,
} from "models/bottling-order";
import { OrderType } from "models/order";
import { LocalVismaUser } from "models/user";
import { FiArrowRight } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { Prompt } from "react-router-dom";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import OrdersService, { OrderBottlingVm } from "services/orders";
import UploadFileService from "services/upload-file-service";
import { useStores } from "stores";
import { formatToString } from "utils/format";
import * as Yup from "yup";
import OrderDone from "../../Index/OrderDone";
import BottlingInfoFormPart, {
  BottlingInfoPartValues,
  bottlingInfoSchema,
} from "./BottlingInfoFormPart";
import BottlingStepper from "./BottlingStepper";
import BottlingSummary from "./BottlingSummary";
import ContactFormPart, {
  BottlingContactPartValues,
  bottlingContactSchema,
} from "./ContactFormPart";
import DeliveryFormPart, {
  BottlingDeliveryPartValues,
  bottlingDeliverySchema,
} from "./DeliveryFormPart";
import { getFileFromCanvas } from "./label-editor/fabricUtils";
import LabelsFormPart, {
  BottlingLabelsPartValues,
  bottlingLabelsSchema,
} from "./LabelsFormPart";
import LoanFormPart, {
  BottlingLoanPartValues,
  bottlingLoanSchema,
} from "./LoanFormPart";

export interface BottlingFormValues {
  loan: BottlingLoanPartValues;
  bottlingInfo: BottlingInfoPartValues;
  labels: BottlingLabelsPartValues;
  delivery: BottlingDeliveryPartValues;
  contact: BottlingContactPartValues;
  showSummary: boolean;
  submitError?: boolean;
  newOrderNumber?: number;
}

async function setUploadFiles(props: FormikProps<BottlingFormValues>) {
  var filePromises = props.values.labels.labels.map((l, i) =>
    getFileFromCanvas(i)
  );
  var files = await Promise.all(filePromises);

  files.forEach(async (file, i) => {
    if (!file) return;
    const uploadFile: UploadFile = { file };

    const imagePreview = await getImagePreview(file);
    const imageMetaData = await getImageMetaData(imagePreview);
    uploadFile.imagePreview = imagePreview;
    uploadFile.imageMetaData = imageMetaData;

    props.setFieldValue(`labels.labels[${i}].imageFile`, uploadFile);
  });
}

const initialValues = (user: LocalVismaUser, email: string) =>
  ({
    loan: {
      isSwedishBankAccount: true,
    },
    bottlingInfo: {
      fullBottling: true,
      dialute: false,
      refillAction: undefined,
    },
    labels: {
      labels: [
        {
          customization: Customization.File,
          percentOfLabels: 100,
          imageFile: undefined,
          optOut: false,
          touched: false,
        },
      ],
    },
    contact: {
      fullName: `${user.firstName} ${user.lastName}`,
      email: email,
      phoneNumber: user.phoneNumber,
    },
    delivery: {},
    showSummary: false,
  } as BottlingFormValues);

const processLabels = async (labelForm: BottleLabel[], labelPrefix: string) => {
  const labels = labelForm.map(async (l, i) => {
    if (l.customization !== Customization.File) return l;

    const labelResp = await UploadFileService.uploadFiles(
      [{ ...l.imageFile!, key: i }],
      labelPrefix
    );

    return { ...l, imageUrl: labelResp.data.uploadedUrl };
  });

  return Promise.all(labels);
};

const BottlingForm = () => {
  const { cask } = useCurrentCask();
  const { localeStore, sessionStore, casksStore } = useStores();
  const isMobile = useMedia("(max-width: 48em)");
  const schema = Yup.object().shape({
    loan: bottlingLoanSchema(formatToString),
    bottlingInfo: bottlingInfoSchema(formatToString),
    labels: bottlingLabelsSchema(formatToString),
    delivery: bottlingDeliverySchema(formatToString),
    contact: bottlingContactSchema(formatToString),
  });

  if (!cask) return null;

  const onSubmit = async (
    values: BottlingFormValues,
    formikHelpers: FormikHelpers<BottlingFormValues>
  ) => {
    // Process labels and send vismaCaskNumber as prefix
    const vismaCaskNumber = cask.vismaCaskNumber || "";
    const labels = await processLabels(values.labels.labels, vismaCaskNumber);

    let order: OrderBottlingVm = {
      caskId: cask!.id,
      bottlingAmount: values.bottlingInfo.fullBottling
        ? BottlingAmount.FullBottling
        : BottlingAmount.PartBottling,
      numberOfBottles: !values.bottlingInfo.fullBottling
        ? values.bottlingInfo.numberOfBottles
        : undefined,
      alcoholContent: values.bottlingInfo.dialute
        ? values.bottlingInfo.alcoholContent
        : undefined,
      additionalLabelInfo:
        values.labels.labels.length > 1
          ? values.labels.labels
              .map((l, i) => `Etikett ${i + 1}: ${l.percentOfLabels}%`)
              .join("\r\n")
          : undefined,
      deliveryOccasionId: values.delivery.bottlingDeliveryOccasion?.id,
      deliveryLocation: values.delivery.deliveryLocation!,
      bottleLabels: labels,
      afterBottlingAction: !values.bottlingInfo.afterBottling
        ? AfterBottlingAction.NotSet
        : Number(values.bottlingInfo.afterBottling!),
      caskSignText: values.bottlingInfo.refillAction
        ? values.bottlingInfo.caskSignText!
        : "",
      fullName: values.contact.fullName,
      email: values.contact.email,
      phoneNumber: values.contact.phoneNumber,
      bankAccountType: values.loan.isSwedishBankAccount
        ? BankAccountType.Swedish
        : BankAccountType.International,
      clearingNumber: values.loan.isSwedishBankAccount
        ? values.loan.clearingNumber
        : undefined,
      bankAccountNumber: values.loan.isSwedishBankAccount
        ? values.loan.swedishBankAccountNumber
        : values.loan.foreignBankAccountNumber,
      language: localeStore.language,
      localVismaUserId: sessionStore.vismaUser.id,
      comment: values.contact.comment,
    };

    try {
      const orderResponse = await OrdersService.orderBottling(order);
      if (orderResponse.hasErrors) {
        formikHelpers.setFieldValue("submitError", true);
      } else {
        await casksStore.fetchCasks(false);
        window.scrollTo(0, 0);
        return formikHelpers.setFieldValue(
          "newOrderNumber",
          orderResponse.data.order.id
        );
      }
    } catch (e) {
      formikHelpers.setFieldValue("submitError", true);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues(
        sessionStore.vismaUser,
        sessionStore.user.email
      )}
      validationSchema={schema}
    >
      {(props) => (
        <>
          <Prompt
            when={props.dirty && !props.values.newOrderNumber}
            message={(location, action) => {
              if (location.pathname.endsWith("/bottling")) return true;

              return formatToString("common.unsaved-changes");
            }}
          />
          <BottlingStepper />
          <Box maxW={isMobile ? undefined : 1024 - 400} w="full">
            {!!props.values.newOrderNumber && (
              <OrderDone
                type={OrderType.Bottling}
                orderNumber={props.values.newOrderNumber}
                email={props.values.contact.email}
              />
            )}
            <Box
              display={
                !props.values.showSummary && !props.values.newOrderNumber
                  ? "block"
                  : "none"
              }
            >
              <LoanFormPart />
              <BottlingInfoFormPart />
              <LabelsFormPart />
              <DeliveryFormPart />
              <ContactFormPart />
              <Box mt={8}>
                <Collapse in={!props.isValid}>
                  <Box mb={4}>
                    <CustomAlert
                      status="warning"
                      labelKey="cask.orders.bottling.show-summary.invalid"
                    />
                  </Box>
                </Collapse>
                <BrandButton
                  large
                  mt={4}
                  w="full"
                  colorScheme="brand"
                  isDisabled={!props.isValid}
                  rightIcon={<FiArrowRight size="1.25rem" />}
                  onClick={async () => {
                    window.scrollTo(0, 0);

                    props.setFieldValue("showSummary", true);
                    await setUploadFiles(props);
                  }}
                >
                  <FormattedMessage id="cask.orders.bottling.show-summary" />
                </BrandButton>
              </Box>
            </Box>
            <Box
              display={
                props.values.showSummary && !props.values.newOrderNumber
                  ? "block"
                  : "none"
              }
            >
              <BottlingSummary />
            </Box>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default observer(BottlingForm);
