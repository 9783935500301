import Compressor from "compressorjs";
import * as fabric from "fabric"; // v6

export function renderCustomIcon(
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  imageElement: HTMLImageElement,
  fabricObject: fabric.Object
) {
  const size = 24; // Desired size of the icon
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
  ctx.drawImage(imageElement, -size / 2, -size / 2, size, size);
  ctx.restore();
}

export function getFileFromCanvas(index: number) {
  const canvasElement = document.getElementById(
    `label-canvas-${index}`
  ) as HTMLCanvasElement;

  return new Promise<File>((resolve) => {
    canvasElement.toBlob((blob) => {
      const file = new File([blob!], "label.jpeg", {
        type: "image/jpeg",
      });

      new Compressor(file, {
        quality: 0.8,
        success(result: File) {
          resolve(result);
        },
        error(err) {
          console.error(err.message);
        },
      });
    });
  });
}
